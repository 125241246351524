import React from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/EgeHeader.css";

function EgeHeader({ setActiveComponent, activeComponent }) {
  const navigate = useNavigate();

  return (
    <header className="ege-header">
      <div className="ege-header-logo" onClick={() => navigate("/")}>
        <img src="/img/logo.svg" alt="Логотип" />
      </div>
      

      <div className="ege-header-navigation">
        <div className="ege-header-nav-section">
          <span>ЧАСТЬ 1</span>
          <div
            className={`ege-header-nav-item ${activeComponent === "part1-sec1" ? "active" : ""}`}
            onClick={() => setActiveComponent("part1-sec1")}
          >
            <img src="/img/tasklist_icon.svg" alt="Раздел 1.1" />
          </div>
          <div
            className={`ege-header-nav-item ${activeComponent === "part1-sec2" ? "active" : ""}`}
            onClick={() => setActiveComponent("part1-sec2")}
          >
            <img src="/img/checklist_ege_icon.svg" alt="Раздел 1.2" />
          </div>
        </div>

        <span className="ege-header-divider">—</span>

        <div className="ege-header-nav-section">
          <span>ЧАСТЬ 2</span>
          <div
            className={`ege-header-nav-item ${activeComponent === "part2-sec1" ? "active" : ""}`}
            onClick={() => setActiveComponent("part2-sec1")}
          >
            <img src="/img/tasklist_icon.svg" alt="Раздел 2.1" />
          </div>
          <div
            className={`ege-header-nav-item ${activeComponent === "part2-sec2" ? "active" : ""}`}
            onClick={() => setActiveComponent("part2-sec2")}
          >
            <img src="/img/checklist_ege_icon.svg" alt="Раздел 2.2" />
          </div>
        </div>
      </div>

      <div className="ege-header-menu-icons">
        <div className="ege-header-menu-item" onClick={() => navigate("/content")}>
          <img src="/img/content_header_icon.svg" alt="Содержание" />
          <span>СОДЕРЖАНИЕ</span>
        </div>
        <div className="ege-header-menu-item" onClick={() => navigate("/profile")}>
          <img src="/img/profile_icon.svg" alt="Профиль" />
          <span>ПРОФИЛЬ</span>
        </div>
      </div>
    </header>
  );
}

export default EgeHeader;
