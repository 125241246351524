import React from "react";
import { useEgeContentSwitcher } from "../assets/scripts/EgeContentSwitcher.js";
import EgeHeader from "./EgeHeader";
import "../assets/styles/EgeContentSwitcher.css";

function EgeContentSwitcher({ Component1, Component2, Component3, Component4, sharedProps }) {
  const { activeComponent, setActiveComponent } = useEgeContentSwitcher();

  const renderContent = () => {
    switch (activeComponent) {
      case "part1-sec1":
        return <Component1 {...sharedProps} />;
      case "part1-sec2":
        return <Component2 {...sharedProps} />;
      case "part2-sec1":
        return <Component3 {...sharedProps} />;
      case "part2-sec2":
        return <Component4 {...sharedProps} />;
      default:
        return <Component1 {...sharedProps} />;
    }
  };

  return (
    <div className="ege-content-switcher">
      <EgeHeader setActiveComponent={setActiveComponent} activeComponent={activeComponent} />
      <div className="ege-test-content-wrapper">{renderContent()}</div>
    </div>
  );
}

export default EgeContentSwitcher;
