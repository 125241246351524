import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { loadAnimationOrImage } from "../assets/script";

const AnimationRenderer = ({ animationPath, animationId }) => {
  const initializedRef = useRef(false); // Флаг для проверки, выполнена ли инициализация

  useEffect(() => {
    const container = document.getElementById(animationId);

    if (container && !initializedRef.current) {
      initializedRef.current = true; // Устанавливаем флаг, чтобы избежать повторной инициализации
      loadAnimationOrImage(animationId, animationPath); // Вызываем функцию для загрузки
    }
  }, [animationPath, animationId]);

  const isAnimation = animationPath?.endsWith(".json");

  return (
    <div className="main-animation-container">
      <div
        id={animationId}
        className={isAnimation ? "lottie-animation-container" : "image-container"}
        data-animation-path={animationPath}
      >
        {!isAnimation && (
          <img
            src={animationPath}
            alt="Illustration"
            className="animation-image"
          />
        )}
      </div>
    </div>
  );
};

AnimationRenderer.propTypes = {
  animationPath: PropTypes.string.isRequired,
  animationId: PropTypes.string.isRequired,
};

export default AnimationRenderer;
