
import React, { useEffect, useState } from "react";
import { initializeCanvas, drawGridWithAxes } from "../../../../assets/interactives/class-7/path-and-trajectory/trajectory-path-script";
import "../../../../assets/interactives/class-7/path-and-trajectory/trajectory-path-styles.css";


function TrajectoryPathTrain() {
    const [path, setPath] = useState(0);
    const [displacement, setDisplacement] = useState(0);

    useEffect(() => {
        const canvas = document.getElementById("trajectoryCanvas");
        drawGridWithAxes(canvas); // Теперь вызов перенесен в trajectory-path-script
        initializeCanvas("trajectoryCanvas", setPath, setDisplacement);
    }, []);

    return (
        <div>
            <canvas id="trajectoryCanvas" width="500" height="500"></canvas>
            <div id="controls">
                <div>
                    <span>Путь: </span>
                    <span className="path-display">{path.toFixed(2)}</span>
                </div>
                <div>
                    <span>Перемещение: </span>
                    <span className="displacement-display">{displacement.toFixed(2)}</span>
                </div>
            </div>
        </div>
    );
}

export default TrajectoryPathTrain;

