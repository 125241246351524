
import React, { useEffect } from "react";
import Navbar from '../../../../components/Navbar';
import Footer from "../../../../components/Footer";
import Sidebar from "../../../../components/Sidebar";
import BottomMenu from "../../../../components/BottomMenu";
import LeftMenuWrapper from "../../../../components/LeftMenuWrapper";
import ButtonContainer from "../../../../components/ButtonContainer";
import ExtraButtonsContainerTest from "../../../../components/ExtraButtonsContainerTest";
import ScrollTopButton from "../../../../components/main_components/ScrollTopButton";
import InertiaTrainer from "../../../../components/interactives/class-7/inertia/InertiaTrain";

	


const Inertia_interactive_7_cl = () => {
	
	return (
	<div>
		<Navbar />
		<Sidebar />
		<LeftMenuWrapper enableCleanup={false}></LeftMenuWrapper>
		<InertiaTrainer 
			title="Инертность тележек"
		/>
		<ExtraButtonsContainerTest
			lessonLink="/courses/physics/7-class/lecture/inertia/"
			interactiveLink="/interactive-trainer"
		/>
		<ButtonContainer
			prevButtonText="Равномерное и неравномерное движение"
			nextButtonText="Ускорение"
			prevPageLink="/uniform-motion"
			nextPageLink="/acceleration"
		/>
		<BottomMenu
			videoId="video-section"
			conspectId="conspect-section"
			interactiveLink="/interactive-trainer"
			testLink="/test"
		/>
		<Footer />
		<ScrollTopButton />
	</div>
	);
}

export default Inertia_interactive_7_cl;