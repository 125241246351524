import React from "react";
import PropTypes from "prop-types";

const TextBlock = ({ text }) => {
  if (!text) return null; // Если текст не передан, не рендерим ничего

  return <div className="main-text-container"><div className="main-text-block">{text}</div></div>;
};

TextBlock.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Основной текст (может быть строкой или JSX)
};

TextBlock.defaultProps = {
  text: null,
};

export default TextBlock;
