import React from "react";
import "../../assets/styles/main_components/FooterMain.css";

function FooterMain() {
    return (
        <footer className="footer-main">
            <div className="footer-main-container">
                <div className="footer-main-col">
                    <img src="/img/logo_footer.svg" alt="Логотип" className="footer-main-logo" />
                    <ul>
                        <li><a href="#">О проекте</a></li>
                        <li><a href="#">Стать автором</a></li>
                        <li><a href="#">Поддержать проект</a></li>
                        <li><a href="#">Связаться</a></li>
                    </ul>
                </div>
                <div className="footer-main-col footer-donation-col">
                </div>
                <div className="footer-main-col footer-image-col">
                    <img src="/img/footer-einstein-1.png" alt="Эйнштейн" className="footer-main-image" />
                </div>
            </div>
        </footer>
    );
}

export default FooterMain;
