
import React from "react";
import { Link } from "react-router-dom";

const ExtraButtonsContainerTest = ({ lessonLink, interactiveLink }) => {
    return (
        <div className="extra-buttons-container">
            <Link to={lessonLink} className="extra-button">
                <img
                    src="/img/test-icon.svg"
                    alt="Иконка Тест"
                    className="extra-button-icon"
                />
                <span>Вернуться к уроку</span>
            </Link>
			<Link to={interactiveLink} className="extra-button">
                <img
                    src="/img/interactive-icon.svg"
                    alt="Иконка Интерактив"
                    className="extra-button-icon"
                />
                <span>Интерактивный тренажёр</span>
            </Link>
        </div>
    );
};

export default ExtraButtonsContainerTest;
