export function setupInertiaTrainer(containerId) {
    const container = document.getElementById(containerId);
    if (!container) {
        console.error(`Container with ID "${containerId}" not found.`);
        return;
    }

    // Получаем элементы управления внутри контейнера
    const leftTrain = container.querySelector('#leftTrainInertiaTrain');
    const rightTrain = container.querySelector('#rightTrainInertiaTrain');
    const leftWeightSmall = container.querySelector('#leftWeightSmallInertiaTrain');
    const leftWeightBig = container.querySelector('#leftWeightBigInertiaTrain');
    const rightWeightSmall = container.querySelector('#rightWeightSmallInertiaTrain');
    const rightWeightBig = container.querySelector('#rightWeightBigInertiaTrain');
    const startButton = container.querySelector('#startExperimentInertiaTrain');
    const resetButton = container.querySelector('#resetInertiaTrain');
    const leftLine = container.querySelector('#leftLineInertiaTrain');
    const rightLine = container.querySelector('#rightLineInertiaTrain');
    const table = container.querySelector('#tableInertiaTrain');
    const commentAreaInertiaTrain = container.querySelector('#commentAreaInertiaTrain');
    const commentText = container.querySelector('#commentTextInertiaTrain');
    const nextStageButton = container.querySelector('#nextStageInertiaTrain');
    const answerForm = container.querySelector('#answerFormInertiaTrain');

    // Проверка наличия всех элементов
    if (
        !leftTrain || !rightTrain || !leftWeightSmall || !leftWeightBig || 
        !rightWeightSmall || !rightWeightBig || !startButton || !resetButton ||
        !leftLine || !rightLine || !table || !commentAreaInertiaTrain || !nextStageButton
    ) {
        console.error('Missing required elements in the container.');
        return;
    }

    // Начальные значения
    let leftMass = 1;
    let rightMass = 1;
    let draggedWeight = null;
    let correctAnswers = 0;

	leftWeightSmall.addEventListener('dragstart', (e) => dragStart(e, 'leftSmall', 0.5));
	leftWeightBig.addEventListener('dragstart', (e) => dragStart(e, 'leftBig', 1));
	rightWeightSmall.addEventListener('dragstart', (e) => dragStart(e, 'rightSmall', 0.5));
	rightWeightBig.addEventListener('dragstart', (e) => dragStart(e, 'rightBig', 1));

	leftTrain.addEventListener('dragover', allowDrop);
	rightTrain.addEventListener('dragover', allowDrop);

	leftTrain.addEventListener('drop', (e) => dropOnTrain(e, 'left'));
	rightTrain.addEventListener('drop', (e) => dropOnTrain(e, 'right'));

	function dragStart(e, weight, mass) {
		draggedWeight = { weight, mass };
		e.dataTransfer.setData('text', weight);
	}

	function allowDrop(e) {
		e.preventDefault();
	}

	// Перемещение гири на тележку и позиционирование с учётом смещения
	function dropOnTrain(e, train) {
		e.preventDefault();
		const { weight, mass } = draggedWeight;

		if (train === 'left') {
			leftMass += mass;
			if (weight === 'leftSmall') {
				// Привязываем маленькую гирю к левой тележке, смещая её немного левее центра
				leftWeightSmall.style.left = `${leftTrain.offsetLeft + (leftTrain.offsetWidth / 2) - leftWeightSmall.offsetWidth}px`; // Смещаем левее
				leftWeightSmall.style.bottom = '29.5%';
				leftWeightSmall.style.position = 'absolute';
				leftWeightSmall.dataset.attached = 'left';
			} else if (weight === 'leftBig') {
				// Привязываем большую гирю к левой тележке, смещая её немного правее центра
				leftWeightBig.style.left = `${leftTrain.offsetLeft + (leftTrain.offsetWidth / 2)}px`; // Смещаем правее
				leftWeightBig.style.bottom = '29.5%';
				leftWeightBig.style.position = 'absolute';
				leftWeightBig.dataset.attached = 'left';
			}
		} else if (train === 'right') {
			rightMass += mass;
			if (weight === 'rightSmall') {
				// Привязываем маленькую гирю к правой тележке, смещая её немного левее центра
				rightWeightSmall.style.left = `${rightTrain.offsetLeft + (rightTrain.offsetWidth / 2)}px`; // Смещаем левее
				rightWeightSmall.style.bottom = '29.5%';
				rightWeightSmall.style.position = 'absolute';
				rightWeightSmall.dataset.attached = 'right';
			} else if (weight === 'rightBig') {
				// Привязываем большую гирю к правой тележке, смещая её немного правее центра
				rightWeightBig.style.left = `${rightTrain.offsetLeft + (rightTrain.offsetWidth / 2) - rightWeightBig.offsetWidth}px`; // Смещаем правее
				rightWeightBig.style.bottom = '29.5%';
				rightWeightBig.style.position = 'absolute';
				rightWeightBig.dataset.attached = 'right';
			}
		}

		draggedWeight = null;
	}




	// Анимация движения тележек и гирей
	startButton.addEventListener('click', () => {
		let totalLeftMass = leftMass;
		let totalRightMass = rightMass;

		// Если гиря привязана к левой тележке, увеличиваем её массу
		if (leftWeightSmall.dataset.attached === 'left') {
			totalLeftMass += 0.5; // Масса маленькой гири
		}
		if (leftWeightBig.dataset.attached === 'left') {
			totalLeftMass += 1; // Масса большой гири
		}

		// Если гиря привязана к правой тележке, увеличиваем её массу
		if (rightWeightSmall.dataset.attached === 'right') {
			totalRightMass += 0.5; // Масса маленькой гири
		}
		if (rightWeightBig.dataset.attached === 'right') {
			totalRightMass += 1; // Масса большой гири
		}

		const totalMass = totalLeftMass + totalRightMass;

		leftTrain.style.transition = 'transform 2s ease';
		rightTrain.style.transition = 'transform 2s ease';
		leftWeightSmall.style.transition = 'transform 2s ease';
		leftWeightBig.style.transition = 'transform 2s ease';
		rightWeightSmall.style.transition = 'transform 2s ease';
		rightWeightBig.style.transition = 'transform 2s ease';

		// Рассчет движения тележек
		if (totalLeftMass === totalRightMass) {
			// Если массы равны, тележки разъезжаются на одинаковое расстояние, но чуть дальше
			const distance = 160 - totalLeftMass * 10; // Увеличено базовое расстояние
			leftTrain.style.transform = `translateX(-${distance}px)`;
			rightTrain.style.transform = `translateX(${distance}px)`;

			// Гири двигаются вместе с тележками
			if (leftWeightSmall.dataset.attached === 'left') {
				leftWeightSmall.style.transform = `translateX(-${distance}px)`;
			}
			if (leftWeightBig.dataset.attached === 'left') {
				leftWeightBig.style.transform = `translateX(-${distance}px)`;
			}
			if (rightWeightSmall.dataset.attached === 'right') {
				rightWeightSmall.style.transform = `translateX(${distance}px)`;
			}
			if (rightWeightBig.dataset.attached === 'right') {
				rightWeightBig.style.transform = `translateX(${distance}px)`;
			}
		} else {
			// Если массы тележек не равны, увеличиваем базовую скорость
			const velocityLeft = (totalRightMass / totalMass) * 270; // Увеличено с 200
			const velocityRight = (totalLeftMass / totalMass) * 270; // Увеличено с 200

			leftTrain.style.transform = `translateX(-${velocityLeft}px)`;
			rightTrain.style.transform = `translateX(${velocityRight}px)`;

			// Гири двигаются вместе с тележками
			if (leftWeightSmall.dataset.attached === 'left') {
				leftWeightSmall.style.transform = `translateX(-${velocityLeft}px)`;
			}
			if (leftWeightBig.dataset.attached === 'left') {
				leftWeightBig.style.transform = `translateX(-${velocityLeft}px)`;
			}
			if (rightWeightSmall.dataset.attached === 'right') {
				rightWeightSmall.style.transform = `translateX(${velocityRight}px)`;
			}
			if (rightWeightBig.dataset.attached === 'right') {
				rightWeightBig.style.transform = `translateX(${velocityRight}px)`;
			}
		}

		// Показать комментарий после остановки тележек
		setTimeout(() => {
			showDashedLines();
			document.getElementById('commentAreaInertiaTrain').style.display = 'block'; // Показать блок с комментариями
		}, 2000);
		
			// Проверка правильности ответа
		const selectedAnswer = document.querySelector('input[name="answer"]:checked');
		if (!selectedAnswer) {
			document.getElementById('comment').textContent = "Выберите ответ!";
			document.getElementById('commentAreaInertiaTrain').style.display = 'block';
			return;
		}
		const answer = selectedAnswer.value;
		

		if (answer == 1) {
			correctAnswers++; // Увеличиваем счётчик правильных ответов
		}
		
			// Показать комментарий и кнопку "Далее", если ответов 3 и более
		setTimeout(() => {
			if (correctAnswers >= 3) {
				document.getElementById('nextStageInertiaTrain').style.display = 'block';
			}
		}, 2000);
	});




	function showDashedLines() {
		// Получаем позиции тележек и стола относительно окна
		const leftTrainRect = leftTrain.getBoundingClientRect();
		const rightTrainRect = rightTrain.getBoundingClientRect();
		const tableRect = table.getBoundingClientRect();

		// Высота тележек (используем их реальную высоту)
		const leftTrainHeight = leftTrainRect.height;
		const rightTrainHeight = rightTrainRect.height;

		// 25% высоты тележки
		const leftTrainStartHeight = leftTrainRect.bottom - leftTrainHeight * 0.15;
		const rightTrainStartHeight = rightTrainRect.bottom - rightTrainHeight * 0.15;

		// Получаем позицию контейнера (line) для корректного позиционирования
		const containerRect = document.querySelector('.line-inertia-train').getBoundingClientRect();

		// Высота линий от 25% высоты тележки до нижней кромки стола
		const leftLineHeight = tableRect.bottom - leftTrainStartHeight;
		const rightLineHeight = tableRect.bottom - rightTrainStartHeight;

		// Задаем высоту и положение левой линии
		leftLine.style.height = `${leftLineHeight}px`;
		leftLine.style.top = `${leftTrainStartHeight - containerRect.top}px`;  // Начало от 25% высоты левой тележки
		leftLine.style.left = `${leftTrainRect.right - containerRect.left}px`;  // Позиция от правой кромки левой тележки
		leftLine.style.visibility = 'visible';

		// Задаем высоту и положение правой линии
		rightLine.style.height = `${rightLineHeight}px`;
		rightLine.style.top = `${rightTrainStartHeight - containerRect.top}px`;  // Начало от 25% высоты правой тележки
		rightLine.style.left = `${rightTrainRect.left - containerRect.left}px`;  // Позиция от левой кромки правой тележки
		rightLine.style.visibility = 'visible';
	}




	// Сброс положения тележек и гирь
	resetButton.addEventListener('click', () => {
		// Сбрасываем тележки
		leftTrain.style.transform = 'translateX(0)';
		rightTrain.style.transform = 'translateX(0)';
		leftMass = 1;
		rightMass = 1;
		
		// Скрыть комментарии после сброса
		document.getElementById('commentAreaInertiaTrain').style.display = 'none';
		
		// Отключаем плавный переход для мгновенного возврата гирь
		leftWeightSmall.style.transition = 'none';
		leftWeightBig.style.transition = 'none';
		rightWeightSmall.style.transition = 'none';
		rightWeightBig.style.transition = 'none';

		// Сбрасываем гири в исходное положение
		leftWeightSmall.style.left = '10%';
		leftWeightSmall.style.bottom = '18.7%';
		leftWeightBig.style.left = '5%';
		leftWeightBig.style.bottom = '18.7%';
		rightWeightSmall.style.left = '87%';
		rightWeightSmall.style.bottom = '18.7%';
		rightWeightBig.style.left = '90%';
		rightWeightBig.style.bottom = '18.7%';

		// Сбрасываем трансформации гирь
		leftWeightSmall.style.transform = 'translateX(0)';
		leftWeightBig.style.transform = 'translateX(0)';
		rightWeightSmall.style.transform = 'translateX(0)';
		rightWeightBig.style.transform = 'translateX(0)';

		// Удаляем привязки
		delete leftWeightSmall.dataset.attached;
		delete leftWeightBig.dataset.attached;
		delete rightWeightSmall.dataset.attached;
		delete rightWeightBig.dataset.attached;

		// Скрываем пунктирные линии
		leftLine.style.visibility = 'hidden';
		rightLine.style.visibility = 'hidden';
		
		// Восстанавливаем плавный переход для следующих движений
		setTimeout(() => {
			leftWeightSmall.style.transition = 'transform 2s ease';
			leftWeightBig.style.transition = 'transform 2s ease';
			rightWeightSmall.style.transition = 'transform 2s ease';
			rightWeightBig.style.transition = 'transform 2s ease';
		}, 10);  // Небольшая задержка для сброса	

	});

	// Переход на другую страницу после трёх правильных ответов
	nextStageButton.addEventListener('click', () => {
		window.location.href = 'nextPage.html'; // Ссылка на другую страницу
	});
}
