import { useState, useEffect } from "react";

// Форматирование времени в HH:MM:SS
export const formatTime = (seconds) => {
  const hrs = Math.floor(seconds / 3600).toString().padStart(2, "0");
  const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
  const secs = (seconds % 60).toString().padStart(2, "0");
  return `${hrs}:${mins}:${secs}`;
};

// Хук для управления таймером
export const useTimer = (initialTime) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      alert("Время истекло!");
    }
  }, [timeLeft]);

  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const progress = (timeLeft / initialTime) * circumference;
  const remainingColor = timeLeft > 1800 ? "#CF9974" : "red";

  return { timeLeft, progress, remainingColor, radius, circumference };
};
