import React from 'react';

function Physics_none_content ({ message = "Курс находится в разработке" }) {
  return (
    <div className="under-construction">
      <div className="under-construction-box">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Physics_none_content;