
import React from "react";
import { Link } from "react-router-dom";

const ExtraButtonsContainer = ({ interactiveLink, testLink }) => {
    return (
        <div className="extra-buttons-container">
            <Link to={interactiveLink} className="extra-button">
                <img
                    src="/img/interactive-icon.svg"
                    alt="Иконка Интерактив"
                    className="extra-button-icon"
                />
                <span>Интерактивный тренажёр</span>
            </Link>
            <Link to={testLink} className="extra-button">
                <img
                    src="/img/test-icon.svg"
                    alt="Иконка Тест"
                    className="extra-button-icon"
                />
                <span>Пройти тестирование</span>
            </Link>
        </div>
    );
};

export default ExtraButtonsContainer;
