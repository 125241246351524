import React, { useEffect } from "react";
import { initializeScrollToTop } from "../../assets/scripts/main_components/scrollTopButton";
import "../../assets/styles/main_components/ScrollTopButton.css";

const ScrollTopButton = ({ prevButtonText, nextButtonText, prevPageLink, nextPageLink }) => {	
    useEffect(() => {
        const cleanupScrollToTop = initializeScrollToTop("scrollToTop");
        return cleanupScrollToTop; // Удаление обработчиков при размонтировании
    }, []);
	
    return (
        <div> 
			<button id="scrollToTop" className="scroll-to-top" title="Наверх">
				<img src="/img/arrow_up.svg" alt="Наверх" />
			</button>
        </div>
    );
};

export default ScrollTopButton;
