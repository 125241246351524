
import React, { useEffect } from "react";
import Navbar from '../../../components/Navbar';
import Footer from "../../../components/Footer";
import Sidebar from "../../../components/Sidebar";
import BottomMenu from "../../../components/BottomMenu";
import LeftMenuWrapper from "../../../components/LeftMenuWrapper";
import ButtonContainer from "../../../components/ButtonContainer";
import ExtraButtonsContainerTest from "../../../components/ExtraButtonsContainerTest";
import TestContentWrapper from "../../../components/TestContentWrapper";
import ScrollTopButton from "../../../components/main_components/ScrollTopButton";
import {
    initializeTest
} from '../../../assets/test-script';

	
const questions = [
	{
		type: 'single',
		text: "Что такое ускорение?",
		answers: [
			{ text: "Физическая величина, характеризующая направление движения тела.", correct: false },
			{ text: "Физическая величина, определяющая быстроту изменения скорости тела.", correct: true },
			{ text: "Физическая величина, показывающая величину силы, действующей на тело.", correct: false },
			{ text: "Физическая величина, определяющая пройденное телом расстояние.", correct: false }
		],
		explanation_correct: "Верно! Ускорение показывает, как быстро изменяется скорость тела.",
		explanation_wrong: "Неправильно. Ускорение — это величина, которая определяет, как быстро изменяется скорость тела."
	},
	{
		type: 'single',
		text: "Каковы единицы измерения ускорения в системе СИ?",
		answers: [
			{ text: "м/с", correct: false },
			{ text: "м/с²", correct: true },
			{ text: "км/ч", correct: false },
			{ text: "Ньютон", correct: false }
		],
		explanation_correct: "Правильно! Единицей измерения ускорения в системе СИ является м/с².",
		explanation_wrong: "Нет, правильный ответ — м/с²."
	},
	{
		type: 'single',
		text: "Что означает вектор ускорения?",
		answers: [
			{ text: "Направление движения тела.", correct: false },
			{ text: "Величину силы, действующей на тело.", correct: false },
			{ text: "Направление и величину изменения скорости тела.", correct: true },
			{ text: "Направление и величину силы тяжести.", correct: false }
		],
		explanation_correct: "Верно! Вектор ускорения показывает, как и в каком направлении изменяется скорость тела.",
		explanation_wrong: "Неправильно. Вектор ускорения показывает, как изменяется скорость тела."
	},
	{
		type: 'single',
		text: "Что такое равноускоренное движение?",
		answers: [
			{ text: "Движение, при котором ускорение постоянно меняется.", correct: false },
			{ text: "Движение, при котором ускорение равно нулю.", correct: false },
			{ text: "Движение, при котором ускорение постоянно по величине и направлению.", correct: true },
			{ text: "Движение, при котором тело замедляется.", correct: false }
		],
		explanation_correct: "Правильно! Равноускоренное движение — это движение с постоянным ускорением.",
		explanation_wrong: "Нет, равноускоренное движение — это движение с постоянным ускорением."
	},
	{
		type: 'single',
		text: "Если тело движется с постоянным ускорением 2 м/с², что это означает?",
		answers: [
			{ text: "Скорость тела увеличивается на 2 м/с за каждую секунду.", correct: true },
			{ text: "Тело проходит 2 метра за каждую секунду.", correct: false },
			{ text: "Скорость тела уменьшается на 2 м/с за каждую секунду.", correct: false },
			{ text: "Ускорение тела уменьшается на 2 м/с².", correct: false }
		],
		explanation_correct: "Верно! Постоянное ускорение в 2 м/с² означает, что скорость тела увеличивается на 2 м/с каждую секунду.",
		explanation_wrong: "Неправильно. Постоянное ускорение в 2 м/с² означает, что скорость тела увеличивается на 2 м/с каждую секунду."
	},
	{
		type: 'single',
		text: "Если тело увеличило свою скорость с 5 м/с до 15 м/с за 5 секунд, каково его ускорение?",
		answers: [
			{ text: "3 м/с²", correct: false },
			{ text: "2 м/с²", correct: true },
			{ text: "5 м/с²", correct: false },
			{ text: "1 м/с²", correct: false }
		],
		explanation_correct: "Правильно! Ускорение составляет 2 м/с², так как скорость увеличилась на 10 м/с за 5 секунд.",
		explanation_wrong: "Неправильно. Ускорение = изменение скорости / время = (15 - 5) м/с / 5 с = 2 м/с²."
	},
	{
		type: 'single',
		text: "Как изменяется ускорение при равноускоренном движении?",
		answers: [
			{ text: "Ускорение уменьшается.", correct: false },
			{ text: "Ускорение увеличивается.", correct: false },
			{ text: "Ускорение остаётся постоянным.", correct: true },
			{ text: "Ускорение сначала увеличивается, а потом уменьшается.", correct: false }
		],
		explanation_correct: "Верно! При равноускоренном движении ускорение остаётся постоянным.",
		explanation_wrong: "Нет, ускорение при равноускоренном движении остаётся постоянным."
	},
	{
		type: 'single',
		text: "Каково ускорение тела, если его скорость остаётся постоянной?",
		answers: [
			{ text: "Ускорение равно нулю.", correct: true },
			{ text: "Ускорение постоянно.", correct: false },
			{ text: "Ускорение положительно.", correct: false },
			{ text: "Ускорение отрицательно.", correct: false }
		],
		explanation_correct: "Верно! Если скорость тела постоянна, его ускорение равно нулю.",
		explanation_wrong: "Нет, ускорение равно нулю, если скорость тела не меняется."
	},
	{
		type: 'single',
		text: "Чем отличается ускорение от скорости?",
		answers: [
			{ text: "Ускорение показывает, насколько изменяется расстояние, а скорость показывает, насколько изменяется время.", correct: false },
			{ text: "Ускорение показывает, насколько изменяется скорость за единицу времени, а скорость — это пройденное расстояние за единицу времени.", correct: true },
			{ text: "Ускорение показывает направление движения тела, а скорость — его величину.", correct: false },
			{ text: "Ускорение и скорость — это одно и то же.", correct: false }
		],
		explanation_correct: "Правильно! Ускорение показывает, как изменяется скорость, а скорость показывает пройденное расстояние за время.",
		explanation_wrong: "Неправильно. Ускорение показывает, как изменяется скорость, а скорость — это пройденное расстояние за время."
	},
	{
		type: 'single',
		text: "Какую роль играет ускорение при падении тел на землю?",
		answers: [
			{ text: "Ускорение замедляет падение тел.", correct: false },
			{ text: "Ускорение увеличивает скорость тел по мере их падения.", correct: true },
			{ text: "Ускорение заставляет тела падать на землю медленно.", correct: false },
			{ text: "Ускорение уменьшает массу падающего тела.", correct: false }
		],
		explanation_correct: "Верно! Ускорение увеличивает скорость тел по мере их падения.",
		explanation_wrong: "Нет, ускорение увеличивает скорость тел по мере их падения."
	},
	{
		type: 'text',
		text: "Напишите определение ускорения:",
		correctAnswer: "Ускорение — это величина, характеризующая изменение скорости тела.",
		explanation_correct: "Правильно! Ускорение показывает, как изменяется скорость тела.",
		explanation_wrong: "Неправильно. Ускорение — это величина, показывающая изменение скорости тела."
	}
];   
	


const Trajectory_path_and_time_of_movement_test_7_cl = () => {
	
	return (
	<div>
		<Navbar />
		<Sidebar />
		<LeftMenuWrapper enableCleanup={false}></LeftMenuWrapper>
		<main>
			<TestContentWrapper
				title="Траектория, путь и перемещение"
				questions={questions}
			/>
		</main>
		<ExtraButtonsContainerTest
			lessonLink="/courses/physics/7-class/lecture/inertia/"
			interactiveLink="/interactive-trainer"
		/>
		<ButtonContainer
			prevButtonText="Равномерное и неравномерное движение"
			nextButtonText="Ускорение"
			prevPageLink="/uniform-motion"
			nextPageLink="/acceleration"
		/>
		<BottomMenu
			videoId="video-section"
			conspectId="conspect-section"
			interactiveLink="/interactive-trainer"
			testLink="/test"
		/>
		<Footer />
		<ScrollTopButton />
	</div>
	);
}

export default Trajectory_path_and_time_of_movement_test_7_cl;