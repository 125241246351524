// trajectory-path-script.js

export function initializeCanvas(canvasId, updatePath, updateDisplacement) {
    const canvas = document.getElementById(canvasId);
    const context = canvas.getContext("2d");

    const gridSize = 20; // Размер одной клетки
    const width = canvas.width;
    const height = canvas.height;
    const centerX = Math.round(width / (2 * gridSize)) * gridSize;
    const centerY = Math.round(height / (2 * gridSize)) * gridSize;

    let isDragging = false;
    let pathLength = 0;
    let displacement = 0;
    let startPoint = { x: centerX, y: centerY };
    let currentPoint = { x: centerX, y: centerY };
    let pathPoints = [startPoint];

    // Рисуем точку
    function drawPoint(x, y) {
        context.beginPath();
        context.fillStyle = "orange";
        context.arc(x, y, 5, 0, 2 * Math.PI);
        context.fill();
    }

    // Рисуем траекторию
    function drawTrajectory() {
        context.beginPath();
        context.strokeStyle = "#f06292";
        context.lineWidth = 2;
        for (let i = 0; i < pathPoints.length - 1; i++) {
            context.moveTo(pathPoints[i].x, pathPoints[i].y);
            context.lineTo(pathPoints[i + 1].x, pathPoints[i + 1].y);
        }
        context.stroke();
    }

    // Рисуем вектор перемещения
    function drawDisplacement() {
        context.beginPath();
        context.strokeStyle = "#64b5f6";
        context.lineWidth = 2;
        context.setLineDash([5, 5]);
        context.moveTo(startPoint.x, startPoint.y);
        context.lineTo(currentPoint.x, currentPoint.y);
        context.stroke();
        context.setLineDash([]);

        displacement = Math.sqrt(
            Math.pow(currentPoint.x - startPoint.x, 2) + Math.pow(currentPoint.y - startPoint.y, 2)
        );
        updateDisplacement(displacement / gridSize); // Обновление перемещения в клетках
    }

    function resetCanvas() {
        context.clearRect(0, 0, width, height);
        drawGridWithAxes(canvas);
        drawTrajectory();
        drawPoint(currentPoint.x, currentPoint.y);
    }

    resetCanvas();

    canvas.addEventListener("mousedown", (event) => {
        if (isDragging || pathLength > 0) return; // Блокировка дальнейшего перемещения после отпускания

        isDragging = true;
        const rect = canvas.getBoundingClientRect();
        const mouseX = Math.round((event.clientX - rect.left) / gridSize) * gridSize;
        const mouseY = Math.round((event.clientY - rect.top) / gridSize) * gridSize;

        if (mouseX === currentPoint.x && mouseY === currentPoint.y) {
            currentPoint = { x: mouseX, y: mouseY };
        }
    });

    canvas.addEventListener("mousemove", (event) => {
        if (!isDragging) return;

        const rect = canvas.getBoundingClientRect();
        const newPoint = {
            x: Math.round((event.clientX - rect.left) / gridSize) * gridSize,
            y: Math.round((event.clientY - rect.top) / gridSize) * gridSize,
        };

        // Проверяем, чтобы движение было только по осям (не по диагонали)
        if ((newPoint.x === currentPoint.x || newPoint.y === currentPoint.y) &&
            (newPoint.x !== currentPoint.x || newPoint.y !== currentPoint.y)) {
            pathPoints.push(newPoint);
            pathLength += Math.sqrt(
                Math.pow(newPoint.x - currentPoint.x, 2) + Math.pow(newPoint.y - currentPoint.y, 2)
            );
            currentPoint = newPoint;
            updatePath(pathLength / gridSize);
            resetCanvas();
            drawDisplacement();
        }
    });

    canvas.addEventListener("mouseup", () => {
        isDragging = false;
    });
}

// Отдельная функция для рисования сетки и осей
export function drawGridWithAxes(canvas) {
    const context = canvas.getContext("2d");
    const gridSize = 20;
    const width = canvas.width;
    const height = canvas.height;
    const centerX = Math.round(width / (2 * gridSize)) * gridSize;
    const centerY = Math.round(height / (2 * gridSize)) * gridSize;

    context.clearRect(0, 0, width, height);

    // Draw grid
    context.beginPath();
    context.strokeStyle = "#ccc";
    for (let x = 0; x < width; x += gridSize) {
        context.moveTo(x, 0);
        context.lineTo(x, height);
    }
    for (let y = 0; y < height; y += gridSize) {
        context.moveTo(0, y);
        context.lineTo(width, y);
    }
    context.stroke();

    // Draw axes
    context.beginPath();
    context.strokeStyle = "#000";
    context.lineWidth = 2;

    // X-axis
    context.moveTo(0, centerY);
    context.lineTo(width, centerY);
    context.moveTo(width - 10, centerY - 5);
    context.lineTo(width, centerY);
    context.lineTo(width - 10, centerY + 5);

    // Y-axis
    context.moveTo(centerX, 0);
    context.lineTo(centerX, height);
    context.moveTo(centerX - 5, 10);
    context.lineTo(centerX, 0);
    context.lineTo(centerX + 5, 10);

    context.stroke();

    // Draw axis labels
    context.font = "12px Arial";
    context.fillStyle = "#000";
    context.textAlign = "center";

    for (let x = -10; x <= 10; x++) {
        const posX = centerX + x * gridSize;
        if (x !== 0 && posX >= 0 && posX <= width) {
            context.fillText(x, posX, centerY - 5);
        }
    }

    for (let y = -10; y <= 10; y++) {
        const posY = centerY - y * gridSize;
        if (y !== 0 && posY >= 0 && posY <= height) {
            context.fillText(y, centerX + 15, posY + 5);
        }
    }
}