const questions_ege_5_1 = [
  {
    text: "Найдите объем куба со стороной 2 м<sup>3</sup>.",
    correctAnswer: "8",
    unit: "м<sup>3</sup>",
  },
  {
    text: "Определите коэффициент трения скольжения для таблицы.",
    table: [
      ["F<sub>тр,</sub> H", "0,8", "1,6", "2,4", "3,2"],
      ["N, H", "2,0", "4,0", "6,0", "8,0"],
    ],
    correctAnswer: "0,4",
    unit: "",
  },
  {
    text: "Рассчитайте кинетическую энергию тела массой 5 кг, движущегося со скоростью 10 м/с<sup>2</sup>.",
    correctAnswer: "250",
    unit: "Дж",
  },
];

export default questions_ege_5_1;
