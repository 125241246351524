import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  addScrolledClass,
  initializeSectionNavigation,
  initializeTableOfContents,
} from "../assets/script";

function LeftMenuWrapper({
  enableCleanupSectionNavigation = true,
  enableCleanupTableOfContents = true,
  enableLectureLink = false,
  videoHref = "#", // Параметр для ссылки видео
  conspectHref = "#", // Параметр для ссылки конспекта
  interactivesHref = "#", // Параметр для ссылки интерактивов
  testsHref = "#", // Параметр для ссылки тестов
}) {
  const location = useLocation(); // Получаем текущий URL

  useEffect(() => {
    // Инициализация добавления класса при прокрутке
    const cleanupScrolledClass = addScrolledClass("leftmenuwrapper");

    // Инициализация навигации по секциям
    const cleanupSectionNavigation = initializeSectionNavigation(
      "video-section",
      "conspect-section",
      "video-button",
      "conspect-button"
    );

    // Инициализация работы с содержанием
    const cleanupTableOfContents = initializeTableOfContents("toc");

    return () => {
      cleanupScrolledClass();
      if (enableCleanupSectionNavigation) {
        cleanupSectionNavigation();
      }
      if (enableCleanupTableOfContents) {
        cleanupTableOfContents();
      }
    };
  }, [enableCleanupSectionNavigation, enableCleanupTableOfContents]);

  return (
    <div className="leftmenuwrapper">
      <a href="index.html" className="logo-link">
        <img src="/img/logo.svg" alt="Логотип" className="logo" />
      </a>
      <ul className="t-menu__list">
        <li className="menu_list_item">
          <a
            className="t-menu__link-item video-button"
            href={enableLectureLink ? videoHref : "#"}
          >
            <img
              src="/img/play.svg"
              alt="Play"
              style={{ width: "20px", height: "20px", marginRight: "7px" }}
            />
            Видеоурок
          </a>
          <a
            className="t-menu__link-item conspect-button"
            href={enableLectureLink ? conspectHref : "#"}
          >
            Конспект
            <img
              src="/img/text.svg"
              alt="Text"
              style={{ width: "20px", height: "20px", marginLeft: "7px" }}
            />
          </a>
          <div className="solid-line"></div>
          <a
            className={`t-menu__link-item interactives-button ${
              location.pathname.includes("/interactives/") ? "active" : ""
            }`}
            href={interactivesHref}
          >
            <img
              src="/img/click.svg"
              alt="Click"
              style={{ width: "20px", height: "20px", marginRight: "7px" }}
            />
            Интерактив
          </a>
          <div className="solid-line"></div>
          <a
            className={`t-menu__link-item tests-button ${
              location.pathname.includes("/tests/") ? "active" : ""
            }`}
            href={testsHref}
          >
            <img
              src="/img/tick.svg"
              alt="Tick"
              style={{ width: "20px", height: "20px", marginRight: "7px" }}
            />
            Задания
          </a>
        </li>
      </ul>

      <div className="link_wrapper">
        <a href="#toc">
          <div className="icon">
            <img
              src="/img/list.svg"
              alt="List"
              style={{ width: "20px", height: "20px", marginRight: "7px" }}
            />
          </div>
          Библиотека
        </a>
      </div>
    </div>
  );
}

export default LeftMenuWrapper;
