import React from "react";
import PropTypes from "prop-types";

const DefinitionBlock = ({ definition }) => {
  if (!definition) return null; // Если definition не передан, компонент не рендерится

  return (
    <div className="definition-container">
    <div className="definition-box">
      {definition}
    </div>
    </div>
  );
};

DefinitionBlock.propTypes = {
  definition: PropTypes.string, // Текст для определения
};

DefinitionBlock.defaultProps = {
  definition: null,
};

export default DefinitionBlock;
