import React from "react";
import { formatTime, useTimer } from "../assets/scripts/EgeTimer.js";
import "../assets/styles/EgeTimer.css";

function EgeTimer({ initialTime }) {
  const { timeLeft, progress, remainingColor, radius, circumference } = useTimer(initialTime);

  return (
    <div className="ege-timer">
      <svg className="ege-timer-circle" viewBox="0 0 120 120">
        <circle className="ege-timer-background" cx="60" cy="60" r={radius} />
        <circle
          className="ege-timer-progress"
          cx="60"
          cy="60"
          r={radius}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
          style={{ stroke: remainingColor }}
        />
        <text x="50%" y="50%" textAnchor="middle" dy=".3em" className="ege-timer-text">
          {formatTime(timeLeft)}
        </text>
      </svg>
    </div>
  );
}

export default EgeTimer;
