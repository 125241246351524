import { useState, useEffect, useRef } from "react";

export const useCalculator = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [expression, setExpression] = useState("");
  const calculatorRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      calculatorRef.current.focus();
    }
  }, [isOpen]);

  const handleButtonClick = (value) => {
    if (value === "=") {
      try {
        setExpression(eval(expression).toString()); // Вычисление выражения
      } catch {
        setExpression("Ошибка");
      }
    } else if (value === "C") {
      setExpression(""); // Очистка
    } else {
      setExpression(expression + value); // Добавление символа
    }
  };

  const handleKeyDown = (event) => {
    const key = event.key;

    if (key.match(/[0-9+\-*/.]/)) {
      setExpression((prev) => prev + key);
    } else if (key === "Enter") {
      handleButtonClick("=");
    } else if (key === "Backspace") {
      setExpression((prev) => prev.slice(0, -1));
    } else if (key === "Escape") {
      setIsOpen(false);
    }
  };

  return {
    isOpen,
    expression,
    calculatorRef,
    setIsOpen,
    handleButtonClick,
    handleKeyDown
  };
};
