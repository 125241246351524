
import React, { useEffect } from "react";
import { initializeSidebarToggle } from "../assets/script";

function Navbar() {
	useEffect(() => {
		// Инициализация функции переключения боковой панели
		const cleanup = initializeSidebarToggle("sidebar-toggle", "sidebar");
		return cleanup; // Удаление обработчиков при размонтировании
	}, []);
	return (
	<nav>
	  <div className="navbar">
		<div className="container-burger nav-container">
		  <input className="checkbox" type="checkbox" name="" id="" />
		  <div className="hamburger-lines">
			<span className="line line1"></span>
			<span className="line line2"></span>
			<span className="line line3"></span>
		  </div>
		  <div className="logo-burger-container">
			<a href="/" className="logo-burger-link">
			  <img src="/img/logo.svg" alt="Логотип" className="logo-burger-icon" />
			</a>
		  </div>
		  <button className="sidebar-toggle">
			<img src="/img/list_side.svg" alt="Sidebar Icon" className="sidebar-icon" />
		  </button>
		  <div className="menu-items">
			<ul>
			  <li><a href="/">Home</a></li>
			  <li><a href="/about">About</a></li>
			  <li><a href="/blogs">Blogs</a></li>
			  <li><a href="/portfolio">Portfolio</a></li>
			  <li><a href="/contact">Contact</a></li>
			</ul>
		  </div>
		</div>
	  </div>
	</nav>
	);
}

export default Navbar;
