export function initializeScrollToTop(buttonId) {
    const scrollToTopButton = document.getElementById(buttonId);

    if (!scrollToTopButton) {
        console.warn(`Button with ID "${buttonId}" not found.`);
        return;
    }

    // Обработчик прокрутки для отображения/скрытия кнопки
    function handleScroll() {
        if (window.scrollY > window.innerHeight) {
            scrollToTopButton.style.display = "flex";
        } else {
            scrollToTopButton.style.display = "none";
        }
    }

    // Обработчик клика для прокрутки наверх
    function handleClick() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    // Добавляем обработчики событий
    window.addEventListener("scroll", handleScroll);
    scrollToTopButton.addEventListener("click", handleClick);

    // Удаляем обработчики при размонтировании компонента
    return () => {
        window.removeEventListener("scroll", handleScroll);
        scrollToTopButton.removeEventListener("click", handleClick);
    };
}