
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { shuffleArrayWithIndexes, 
		 handleDragStart, 
		 handleDragOver, 
		 handleDrop, 
		 finishTest, 
		 checkAnswer, 
		 isSubmitButtonEnabled,
		 resetAnswers} from '../assets/test-script';


const TestContentWrapper = ({ title, questions }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [shuffledAnswers, setShuffledAnswers] = useState([]);
  const [isCorrect, setIsCorrect] = useState(null); // null, true, or false
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [textInputValue, setTextInputValue] = useState("");
  const [errors, setErrors] = useState(0); // Количество ошибок
  const [answeredQuestions, setAnsweredQuestions] = useState(new Map()); // Хранение статусов вопросов
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false); // Состояние кнопки "Ответить"

  const currentQuestion = questions[currentQuestionIndex];

    const handleTextInputChange = (e) => {
        const value = e.target.value;
        const updatedQuestions = [...questions];
		
        updatedQuestions[currentQuestionIndex].userAnswer = value;
        setShuffledAnswers(value); // Для текстовых вопросов используем строку
    };
	

	useEffect(() => {
		if (
			["single", "multiple", "match", "order"].includes(currentQuestion.type) &&
			Array.isArray(currentQuestion.answers)
		) {
			setShuffledAnswers(shuffleArrayWithIndexes(currentQuestion.answers));
		} else if (currentQuestion.type === "text") {
			setShuffledAnswers(currentQuestion.userAnswer || ""); // Используем строку для текстовых вопросов
		} else {
			setShuffledAnswers([]);
		}
		setIsCorrect(null); // Сброс правильности ответа при переключении вопроса
	}, [currentQuestion]);

	useEffect(() => {
		setIsButtonEnabled(isSubmitButtonEnabled(currentQuestion, shuffledAnswers));
	}, [currentQuestion, shuffledAnswers]);

	const handleQuestionChange = (index) => {
		if (index >= 0 && index < questions.length) {
			// Если повторный клик на активный вопрос
			if (index === currentQuestionIndex) {
				const currentQuestion = questions[currentQuestionIndex];
				
				// Проверяем, был ли вопрос уже отвечен
				const isAnswered = answeredQuestions.has(index);
				setIsSubmitDisabled(isAnswered);

				// Сбрасываем ответы для текущего вопроса
				resetAnswers(currentQuestion);

				// Инициализация состояния ответов для нового вопроса
				const newQuestion = questions[index];
				if (newQuestion.type !== "text") {
					setShuffledAnswers(newQuestion.answers || []);
				}

				// Сбрасываем визуальное состояние радиокнопок
				document.querySelectorAll("input[type='radio']").forEach((radio) => {
					radio.checked = false;
				});

				return; // Завершаем выполнение
			}
			
			document.querySelectorAll("input[type='radio']").forEach((radio) => {
				radio.checked = false;
			});

			// Смена активного вопроса
			setCurrentQuestionIndex(index);
			
			// Проверяем, был ли вопрос уже отвечен
			const isAnswered = answeredQuestions.has(index);
			setIsSubmitDisabled(isAnswered);

			// Обновляем классы для кнопок навигации
			const buttons = document.querySelectorAll(".question-number button");
			buttons.forEach((button) => button.classList.remove("active"));

			const activeButton = buttons[index];
			if (activeButton) {
				activeButton.classList.add("active");
			}

			// Инициализация состояния ответов для нового вопроса
			const newQuestion = questions[index];
			if (newQuestion.type !== "text") {
				setShuffledAnswers(newQuestion.answers || []);
			}
		}
	};

	const submitAnswer = () => {
		const currentQuestion = questions[currentQuestionIndex];
		const result = checkAnswer(currentQuestion, shuffledAnswers);

		setIsCorrect(result);

		// Обновляем статус вопроса как правильно или неправильно отвеченного
		setAnsweredQuestions((prev) => {
			const updatedSet = new Map(prev); // Используем Map для хранения статуса (true/false)
			updatedSet.set(currentQuestionIndex, result);
			return updatedSet;
		});

		// Увеличиваем счётчик ошибок, если ответ неверный
		if (!result) {
			setErrors((prev) => prev + 1);
		}

		// Отключаем кнопку "Ответить" для текущего вопроса
		setIsSubmitDisabled(true);


	};


  const checkIfAllCorrect = () => {
    return answeredQuestions.size === questions.length;
  };
	  
	const retryTest = () => {
		setErrors(0); // Сброс количества ошибок
		setAnsweredQuestions(new Map()); // Очистка статуса отвеченных вопросов
		setCurrentQuestionIndex(0); // Сброс текущего вопроса
		setIsSubmitDisabled(false); // Включение кнопки "Ответить"
		
		// Сбрасываем ответы для всех вопросов
		questions.forEach(resetAnswers);

		// Инициализируем ответы для первого вопроса
		const firstQuestion = questions[0];
		if (firstQuestion.type === "text") {
			setShuffledAnswers(""); // Пустая строка для текстового ответа
		} else if (Array.isArray(firstQuestion.answers)) {
			setShuffledAnswers(shuffleArrayWithIndexes(firstQuestion.answers));
		} else {
			setShuffledAnswers([]); // Пустой массив для остальных случаев
		}
	};

  return (
	<div id="test-content-wrapper">
      {/* Навигация по тесту */}
      <div className="test-navigation-wrapper">
	  <div className="tests-label" id="tests-section">
		<img src="/img/tick-icon.svg" alt="Tests Icon" />
		<span>ЗАДАНИЯ</span>
	  </div>
        <button id="back-button" className="nav-button left" onClick={() => handleQuestionChange(currentQuestionIndex - 1)} disabled={currentQuestionIndex === 0}>
          <img src="/img/left_arrow.svg" alt="Назад" />
        </button>
        <div className="question-number" id="question-number" >
          {questions.map((_, index) => (
			<button
				key={index}
				className={`question-number-button ${index === currentQuestionIndex ? "active" : ""} ${
					answeredQuestions.has(index) ? (answeredQuestions.get(index) ? "correct" : "incorrect") : ""
				}`}
				onClick={() => handleQuestionChange(index)}
			>
				{answeredQuestions.has(index)
					? answeredQuestions.get(index)
						? "✔"
						: "✘"
					: index + 1}
			</button>
          ))}
        </div>
        <button id="skip-button" className="nav-button right"  onClick={() => handleQuestionChange(currentQuestionIndex + 1)} disabled={currentQuestionIndex === questions.length - 1}>
          <img src="/img/right_arrow.svg" alt="Далее" />
        </button>
      </div>

      {/* Отображение текущего вопроса */}
      <div className="question-area">
	  <div className="question-block">
        <h2><img src="/img/question-icon.svg"/> {currentQuestion.text}</h2>
		<div className="answers">
		  {currentQuestion.type === "single" && (
			shuffledAnswers.map((answer, idx) => (
			  <div key={idx} className="radio">
				<input
				  id={`radio-${idx}`}
				  name="answer"
				  type="radio"
				  value={answer.originalIndex}
				  onChange={() =>
					setShuffledAnswers((prev) =>
					  prev.map((a, i) => ({
						...a,
						selected: i === idx,
					  }))
					)
				  }
				/>
				<label htmlFor={`radio-${idx}`} className="radio-label">
				  {answer.text}
				</label>
			  </div>
			))
		  )}

		  {currentQuestion.type === "multiple" && (
			shuffledAnswers.map((answer, idx) => (
			  <div key={idx} className="checkbox">
				<input
				  id={`checkbox-${idx}`}
				  name="answer"
				  type="checkbox"
				  value={answer.originalIndex}
				  onChange={(e) =>
					setShuffledAnswers((prev) =>
					  prev.map((a, i) => ({
						...a,
						selected: i === idx ? e.target.checked : a.selected,
					  }))
					)
				  }
				/>
				<span className="checkbox__icon">✔</span>
				<label htmlFor={`checkbox-${idx}`} className="checkbox-label">
				  {answer.text}
				</label>
			  </div>
			))
		  )}

		  {currentQuestion.type === "match" && (
			shuffledAnswers.map((pair, idx) => (
			  <div key={idx} className="match-item">
				<label>{pair.term}</label>
				<select
				  onChange={(e) =>
					setShuffledAnswers((prev) =>
					  prev.map((p, i) => ({
						...p,
						selectedValue:
						  i === idx ? e.target.value : p.selectedValue,
					  }))
					)
				  }
				>
				  <option value="">--Выберите--</option>
				  {pair.options.map((option, optionIdx) => (
					<option key={optionIdx} value={option}>
					  {option}
					</option>
				  ))}
				</select>
			  </div>
			))
		  )}

		  {currentQuestion.type === "order" && (
			<div className="order">
			  {shuffledAnswers.map((item, idx) => (
				<div
				  key={idx}
				  className="draggable"
				  draggable
				  data-index={idx}
				  onDragStart={handleDragStart}
				  onDragOver={handleDragOver}
				  onDrop={(e) => handleDrop(e, shuffledAnswers, setShuffledAnswers)}
				  style={{
					border: "1px solid black",
					padding: "10px",
					cursor: "move",
				  }}
				>
				  {item.text}
				</div>
			  ))}
			</div>
		  )}

		  {currentQuestion.type === "text" && (
                        <div className="field">
                            <label htmlFor="text-answer"></label>
                            <input
                                type="text"
                                id="text-answer"
                                className="text-input"
                                placeholder="Введите ваш ответ..."
                                value={shuffledAnswers || ""}
                                onChange={handleTextInputChange}
                            />
                            <div className="line"></div>
                        </div>
		  )}
		</div>
    </div>
	<div className="explanation-container">
        {/* Кнопка "Ответить" */}
        <button onClick={submitAnswer} className={`submit-button ${isButtonEnabled ? "active" : "disabled"}`} disabled={!isButtonEnabled} id="submit-button">
          Подтвердить ответ <img src="/img/submit-next.svg" />
        </button>
	  {/* Завершение теста */}
		{answeredQuestions.size === questions.length ? (
			errors > 2 ? (
				<button id="retry-button" onClick={retryTest}>
					Попробовать ещё раз
				</button>
			) : (
				<button id="finish-button" onClick={finishTest}>
					Завершить тест
				</button>
			)
		) : null}

        {/* Объяснение */}
		{isCorrect !== null && (
		  <div id="explanation">
			<p
			  className={isCorrect ? "correct-text" : "wrong-text"}
			  style={{ fontWeight: "bold" }}
			>
			  {isCorrect ? "Верно!" : "Неверно!"}
			</p>
			<p>
			  {isCorrect
				? currentQuestion.explanation_correct
				: currentQuestion.explanation_wrong}
			</p>
		  </div>
		)}
	  </div>


	</div>
    </div>
  );
};

TestContentWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      answers: PropTypes.array,
    })
  ).isRequired,
};

export default TestContentWrapper;