import React from "react";
import PropTypes from "prop-types";

const TitleBlock = ({ title, target }) => {
  if (!title) return null; // Если заголовок не передан, компонент не рендерится

  return (
    <div className="title-container">
	<hr className="delimeter-line" style={{ marginTop: '40px' }} />
    <div id={target?.replace("#", "")} className="icon-paragraph">
      <img src="/img/paragraph.svg" alt="Paragraph" className="icon-image" />
      <span className="icon-text">{title}</span>
    </div>
    </div>
  );
};

TitleBlock.propTypes = {
  title: PropTypes.string, // Текст заголовка
  target: PropTypes.string, // Целевой id для ссылки, если нужно
};

TitleBlock.defaultProps = {
  title: null,
  target: null,
};

export default TitleBlock;
