
import React, { useEffect } from "react";
import MainHeader from "../components/MainHeader";
import TabSwitcher from "../components/main_components/TabSwitcher";
import Physics_class_7_content from "../components/school/Physics_class_7_content";
import Physics_none_content from "../components/Physics_none_content";
import FooterMain from "../components/main_components/FooterMain";
import ScrollTopButton from "../components/main_components/ScrollTopButton";
import DelimeterLine from "../components/DelimeterLine";

function Physics_main() {

	const tabs = ['7 класс', '8 класс', '9 класс', '10 класс', '11 класс'];

	const content = {
	'7 класс': <Physics_class_7_content />,
	'8 класс': <Physics_none_content message = "Курс для 8 класса находится в разработке" />,
	'9 класс': <Physics_none_content message = "Курс для 9 класса находится в разработке" />,
	'10 класс': <Physics_none_content message = "Курс для 10 класса находится в разработке" />,
	'11 класс': <Physics_none_content message = "Курс для 11 класса находится в разработке" />,
	};

	return (
	<div>
		<MainHeader />
		<TabSwitcher tabs={tabs} content={content} title="Физика" />
		<FooterMain />
		<ScrollTopButton />
	</div>
	);
}

export default Physics_main;
