import lottie from 'lottie-web';


export function loadLottieAnimation(containerId) {
    const container = document.getElementById(containerId);

    if (container) {
        const animationPath = container.getAttribute('data-animation-path');
        if (!animationPath) {
            console.warn(`Lottie animation path is not defined for container: ${containerId}`);
            return;
        }

        lottie.loadAnimation({
            container: container,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: animationPath
        });
    } else {
        console.warn(`Container with ID "${containerId}" not found.`);
    }
}


export function addScrolledClass(menuClass) {
    function handleScroll() {
        const menu = document.querySelector(`.${menuClass}`);
        if (menu) {
            if (window.scrollY > 0) {
                menu.classList.add("scrolled");
            } else {
                menu.classList.remove("scrolled");
            }
        }
    }

    // Добавляем обработчик события прокрутки
    window.addEventListener("scroll", handleScroll);

    // Удаляем обработчик при завершении работы
    return () => {
        window.removeEventListener("scroll", handleScroll);
    };
}



export function initializeLottieAnimations(animationIds) {
    function init() {
        animationIds.forEach((id) => {
            loadLottieAnimation(id);
        });
    }

    // Инициализация при вызове
    init();
}


export function initializeSectionNavigation(videoId, conspectId, videoButtonClass, conspectButtonClass) {
    const videoSection = document.getElementById(videoId);
    const conspectSection = document.getElementById(conspectId);
    const videoButton = document.querySelector(`.${videoButtonClass}`);
    const conspectButton = document.querySelector(`.${conspectButtonClass}`);

    if (!videoSection || !conspectSection || !videoButton || !conspectButton) {
        console.warn("One or more required elements are missing.");
        return () => {}; // Возвращаем пустую функцию
    }

    function smoothScrollTo(element) {
        const targetPosition = element.getBoundingClientRect().top + window.scrollY - 80;

        window.scrollTo({
            top: targetPosition,
            behavior: "smooth",
        });
        setTimeout(updateActiveButton, 300);
    }

    function updateActiveButton() {
        const videoRect = videoSection.getBoundingClientRect();
        const conspectRect = conspectSection.getBoundingClientRect();

        const videoInView = videoRect.top < window.innerHeight && videoRect.bottom > 0;
        const conspectInView = conspectRect.top < window.innerHeight && conspectRect.bottom > 0;

        if (videoInView) {
            videoButton.classList.add("active");
            conspectButton.classList.remove("active");
        } else if (conspectInView) {
            conspectButton.classList.add("active");
            videoButton.classList.remove("active");
        }
    }

    videoButton.addEventListener("click", (event) => {
        event.preventDefault();
        smoothScrollTo(videoSection);
    });

    conspectButton.addEventListener("click", (event) => {
        event.preventDefault();
        smoothScrollTo(conspectSection);
    });

    window.addEventListener("scroll", updateActiveButton);
    updateActiveButton();

    // Возвращаем функцию очистки
    return () => {
        videoButton.removeEventListener("click", () => smoothScrollTo(videoSection));
        conspectButton.removeEventListener("click", () => smoothScrollTo(conspectSection));
        window.removeEventListener("scroll", updateActiveButton);
    };
}



export function initializeSidebarScroll(sidebarClass, sidebarMenuClass) {
    const sidebar = document.querySelector(`.${sidebarClass}`);
    const sidebarMenu = document.querySelector(`.${sidebarMenuClass}`);

    if (!sidebar || !sidebarMenu) {
        console.warn("Sidebar or sidebar menu element not found.");
        return;
    }

    // Обработчик для сворачивания меню
    function handleMouseLeave() {
        sidebarMenu.scrollTo({
            top: 0,
            behavior: "auto", // Плавная прокрутка
        });
    }

    sidebar.addEventListener("mouseleave", handleMouseLeave);

    // Удаляем обработчик при размонтировании компонента
    return () => {
        sidebar.removeEventListener("mouseleave", handleMouseLeave);
    };
}


export function initializeSidebarSearch(sidebarClass, searchInputId, searchBarClass, menuItemSelector) {
    const searchInput = document.getElementById(searchInputId);
    const searchBar = document.querySelector(`.${searchBarClass}`);
    const sidebar = document.querySelector(`.${sidebarClass}`);
    const menuItems = document.querySelectorAll(menuItemSelector);

    if (!searchInput || !searchBar || !sidebar || menuItems.length === 0) {
        console.warn("One or more required elements for sidebar search not found.");
        return;
    }

    // Фильтрация меню при вводе текста в строку поиска
    function handleSearchInput() {
        const query = searchInput.value.toLowerCase();
        menuItems.forEach((item) => {
            const text = item.textContent.toLowerCase();
            if (item.classList.contains("no-filter") || text.includes(query)) {
                item.style.display = "block"; // Показываем элемент
            } else {
                item.style.display = "none"; // Скрываем элемент
            }
        });
    }

    // Обработчик для скрытия строки поиска при сворачивании меню
    function handleSidebarMouseLeave() {
        if (!sidebar.matches(":hover")) {
            searchInput.value = ""; // Очищаем поле поиска
            searchBar.style.opacity = "0"; // Прозрачность 0
            searchBar.style.visibility = "hidden"; // Скрываем строку поиска
            menuItems.forEach((item) => {
                item.style.display = "block"; // Показываем все элементы меню
            });
        }
    }

    // Обработчик для показа строки поиска при наведении на меню
    function handleSidebarMouseEnter() {
        searchBar.style.opacity = "1"; // Делаем видимой
        searchBar.style.visibility = "visible"; // Включаем видимость
    }

    // Добавляем обработчики событий
    searchInput.addEventListener("input", handleSearchInput);
    sidebar.addEventListener("mouseleave", handleSidebarMouseLeave);
    sidebar.addEventListener("mouseenter", handleSidebarMouseEnter);

    // Удаляем обработчики при размонтировании компонента
    return () => {
        searchInput.removeEventListener("input", handleSearchInput);
        sidebar.removeEventListener("mouseleave", handleSidebarMouseLeave);
        sidebar.removeEventListener("mouseenter", handleSidebarMouseEnter);
    };
}


export function initializeTableOfContents(tocClass, offset = 100) {
  const tocLinks = document.querySelectorAll(`.${tocClass} a`);

  if (tocLinks.length === 0) {
    console.warn(`No links found in TOC with class "${tocClass}".`);
    return () => {}; // Возвращаем пустую функцию для очистки
  }

  // Функция прокрутки
  function handleClick(event) {
    event.preventDefault(); // Предотвращаем стандартное поведение ссылки

    const targetId = event.target.getAttribute("data-target");
    const targetElement = document.querySelector(targetId);

    if (targetElement) {
      const offsetPosition =
        targetElement.getBoundingClientRect().top +
        window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }

  // Добавляем обработчики кликов для всех ссылок
  tocLinks.forEach((link) => {
    link.addEventListener("click", handleClick);
  });

  // Возвращаем функцию для удаления обработчиков при размонтировании
  return () => {
    tocLinks.forEach((link) => {
      link.removeEventListener("click", handleClick);
    });
  };
}




export function initializeSidebarToggle(toggleClass, sidebarClass) {
    const sidebarToggle = document.querySelector(`.${toggleClass}`);
    const sidebar = document.querySelector(`.${sidebarClass}`);

    if (!sidebarToggle || !sidebar) {
        console.warn(`Sidebar toggle button or sidebar element not found.`);
        return;
    }

    // Обработчик для переключения класса "expanded"
    function handleToggle() {
        sidebar.classList.toggle("expanded");
    }

    // Добавляем обработчик события
    sidebarToggle.addEventListener("click", handleToggle);

    // Удаляем обработчик при размонтировании компонента
    return () => {
        sidebarToggle.removeEventListener("click", handleToggle);
    };
}



export function initializeDynamicSlider(sliderId, dotsClass, prevClass, nextClass, slidesData) {
    const sliderContainer = document.querySelector(`#${sliderId}`);
    const dotsContainer = document.querySelector(`.${dotsClass}`);

    if (!sliderContainer || !dotsContainer || !slidesData || slidesData.length === 0) {
        console.warn("Slider container, dots container, or slides data is missing.");
        return;
    }

    // Очищаем предыдущие слайды и маркеры
    sliderContainer.innerHTML = "";
    dotsContainer.innerHTML = "";

    // Генерация слайдов и точек
    slidesData.forEach((slide, index) => {
        const slideElement = document.createElement("div");
        slideElement.className = `slide ${index === 0 ? "active" : ""}`;
        slideElement.innerHTML = `
            <div class="slide-content">
                <div class="image-side">
                    <img src="${slide.image}" alt="Slide ${index + 1}">
                </div>
                <div class="divider-line"></div>
                <div class="text-side">
                    <p>${slide.text}</p>
                </div>
            </div>
        `;
        sliderContainer.appendChild(slideElement);

        const dotElement = document.createElement("span");
        dotElement.className = `dot ${index === 0 ? "active" : ""}`;
        dotElement.dataset.index = index;
        dotsContainer.appendChild(dotElement);
    });
}

export function initializeSlider(sliderId, prevClass, nextClass, dotClass) {
    const slider = document.getElementById(sliderId);
    const slides = slider?.querySelectorAll(".slide");
    const dots = document.querySelectorAll(`.${dotClass}`);
    const prevButton = document.querySelector(`.${prevClass}`);
    const nextButton = document.querySelector(`.${nextClass}`);

    if (!slider || !slides || slides.length === 0 || !dots || dots.length === 0) {
        console.error("Slides, dots, or navigation buttons are missing.");
        return;
    }

    let currentSlideIndex = 0;

    // Функция для показа слайда
    function showSlide(index) {
        if (!slides[currentSlideIndex] || !dots[currentSlideIndex]) {
            console.error("Slide or dot not found for index:", currentSlideIndex);
            return;
        }

        currentSlideIndex = (index + slides.length) % slides.length;

        // Убираем класс 'active' со всех слайдов и маркеров
        slides.forEach((slide) => slide?.classList?.remove("active"));
        dots.forEach((dot) => dot?.classList?.remove("active"));

        // Добавляем класс 'active' текущему слайду и маркеру
        slides[currentSlideIndex]?.classList?.add("active");
        dots[currentSlideIndex]?.classList?.add("active");
    }

    // Обработчики событий
    prevButton.addEventListener("click", () => {
        showSlide(currentSlideIndex - 1);
    });

    nextButton.addEventListener("click", () => {
        showSlide(currentSlideIndex + 1);
    });

    dots.forEach((dot) => {
        dot.addEventListener("click", () => {
            const index = parseInt(dot.dataset.index, 10);
            if (!isNaN(index)) showSlide(index);
        });
    });

    // Показываем первый слайд
    showSlide(0);

    // Возвращаем функцию для удаления обработчиков
    return () => {
        prevButton.removeEventListener("click", () => showSlide(currentSlideIndex - 1));
        nextButton.removeEventListener("click", () => showSlide(currentSlideIndex + 1));
        dots.forEach((dot) => {
            dot.removeEventListener("click", () => showSlide(parseInt(dot.dataset.index, 10)));
        });
    };
}

export function formatHTMLContent(content) {
    return { __html: content };
}

export function loadAnimationOrImage(id, path) {
  const container = document.getElementById(id);

  if (!container) {
    console.error(`Container with id "${id}" not found.`);
    return;
  }

  if (!path) {
    console.error(`Path is not defined for container with id "${id}".`);
    return;
  }

  if (container.dataset.initialized === "true") {
    console.log(`Container "${id}" is already initialized.`);
    return;
  }

  container.dataset.initialized = "true";

  if (path.endsWith(".json")) {
    lottie.loadAnimation({
      container,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path,
    });
  } else {
    container.innerHTML = `<img src="${path}" alt="Illustration" />`;
  }
}



export function initializeAnimations(animationIds) {
    function init() {
        animationIds.forEach((id) => {
            loadAnimationOrImage(id);
        });
    }

    // Инициализация при вызове
    init();
}