import React from "react";
import PropTypes from "prop-types";

const VideoBlock = ({ videoSrc }) => {
  if (!videoSrc) return null; // Если ссылка на видео не передана, компонент не рендерится

  return (
    <div className="video-container">
      <div className="video-label" id="video-section">
		<img src="/img/video.svg" alt="Video Icon" />
		<span>ВИДЕОУРОК</span>
	  </div>
      <div className="video-wrapper">
        <div className="video-responsive">
          <iframe
            src={videoSrc}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
        <hr className="hr-vertical-lines" style={{ marginTop: '70px' }} />
    </div>
  );
};

VideoBlock.propTypes = {
  videoSrc: PropTypes.string.isRequired, // Ссылка на видео (YouTube или другой источник)
};

export default VideoBlock;
