
import React from "react";
import { Link } from "react-router-dom";

const ButtonContainer = ({ prevButtonText, nextButtonText, prevPageLink, nextPageLink }) => {
    return (
        <div className="button-container">
            <Link to={prevPageLink} className="button-next-prev left-align" role="button">
                <img src="/img/prev-page.svg" alt="Назад" className="button-icon" />
                <div className="text-button">{prevButtonText}</div>
            </Link>
            <Link to={nextPageLink} className="button-next-prev right-align" role="button">
                <img src="/img/next-page.svg" alt="Вперед" className="button-icon" />
                <div className="text-button">{nextButtonText}</div>
            </Link>
        </div>
    );
};

export default ButtonContainer;
