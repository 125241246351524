
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Physics_main from "./pages/Physics_main";
import PhysicsTaskSheet_5_1 from "./pages/ege/physics/part-1/PhysicsTaskSheet_5_1";
import What_is_physics_lecture_7_cl from "./pages/lecture/class-7/What_is_physics_lecture_7_cl";
import Inertia_lecture_7_cl from "./pages/lecture/class-7/Inertia_lecture_7_cl";
import Inertia_test_7_cl from "./pages/tests/class-7/Inertia_test_7_cl";
import Inertia_interactive_7_cl_main from "./pages/interactives/class-7/inertia/Inertia_interactive_7_cl_main";
import Inertia_interactive_7_cl from "./pages/interactives/class-7/inertia/Inertia_interactive_7_cl";
import Trajectory_path_and_time_of_movement_lecture_7_cl from "./pages/lecture/class-7/Trajectory_path_and_time_of_movement_lecture_7_cl";
import Trajectory_path_and_time_of_movement_test_7_cl from "./pages/tests/class-7/Trajectory_path_and_time_of_movement_test_7_cl";
import Trajectory_path_and_time_of_movement_interactive_7_cl from "./pages/interactives/class-7/path-and-trajectory/Trajectory_path_and_time_of_movement_interactive_7_cl";
import YandexMetrika from "./components/YandexMetrika";

function useYandexMetrika(counterId) {
  const location = useLocation();

  React.useEffect(() => {
    if (window.ym) {
      window.ym(counterId, "hit", location.pathname);
    }
  }, [location, counterId]);
}

function App() {
  const counterId = 99216924; // Ваш ID счётчика

  return (
    <Router>
      {/* Добавляем компонент Яндекс.Метрики */}
      <YandexMetrika counterId={counterId} />
      {/* Встраиваем хук для отслеживания переходов */}
      <RoutesWithMetrika counterId={counterId} />
    </Router>
  );
}

// Обёртка для маршрутов с Яндекс.Метрикой
function RoutesWithMetrika({ counterId }) {
  useYandexMetrika(counterId);

  return (
    <Routes>
      <Route path="/" element={<Physics_main />} />
	  <Route path="/courses/physics-school" element={<Physics_main />} />
      <Route path="/courses/physics-school/7-class/lecture/introduction" element={<What_is_physics_lecture_7_cl />} />
      <Route path="/courses/physics-school/7-class/lecture/inertia" element={<Inertia_lecture_7_cl />} />
      <Route path="/courses/physics-school/7-class/tests/inertia" element={<Inertia_test_7_cl />} />
      <Route path="/courses/physics-school/7-class/interactive/inertia" element={<Inertia_interactive_7_cl_main />} />
      <Route path="/courses/physics-school/7-class/interactive/inertia/train-inertness" element={<Inertia_interactive_7_cl />} />
      <Route path="/courses/physics-school/7-class/lecture/trajectory-path-and-time-of-movement" element={<Trajectory_path_and_time_of_movement_lecture_7_cl />} />
      <Route path="/courses/physics-school/7-class/tests/trajectory-path-and-time-of-movement" element={<Trajectory_path_and_time_of_movement_test_7_cl />} />
      <Route path="/courses/physics-school/7-class/interactive/trajectory-path-and-time-of-movement/trajectory-and-moving" element={<Trajectory_path_and_time_of_movement_interactive_7_cl />} />
      <Route path="/ege/physics-school/part-1/tests/test-5-1" element={<PhysicsTaskSheet_5_1 />} />
    </Routes>
  );
}

export default App;