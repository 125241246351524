import React, { useState } from "react";
import "../assets/styles/MainHeader.css";

function MainHeader() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="main-header">
      <div className="top-bar">
        {/* Бургер-меню только для мобильных и планшетов */}
        <button className="burger-menu" onClick={() => setMenuOpen(true)}>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
        </button>

        {/* Логотип по центру */}
        <div className="header-logo">
          <img src="/img/logo.svg" alt="Логотип" />
        </div>

        {/* Кнопка регистрации для десктопа */}
        <div className="auth-buttons desktop">
          <button className="login-button">Регистрация</button>
        </div>

        {/* Кнопка авторизации в виде иконки для мобильных и планшетов */}
        <div className="auth-buttons mobile">
          <button className="login-button">
            <img src="/img/login_icon.svg" alt="Вход" className="login-icon" />
          </button>
        </div>
      </div>

      <div className="divider"></div>

      {/* Основное меню для больших экранов */}
      <div className="main-bar">
        <nav className="navigation">
          <a href="#about" className="nav-link">ФИЗИКА ДЛЯ ВСЕХ</a>
          <a href="#subjects" className="nav-link">ШКОЛЬНЫЙ КУРС</a>
          <a href="#journal" className="nav-link">ЕГЭ/ОГЭ</a>
          <a href="#journal" className="nav-link">УНИВЕРСИТЕТ</a>
          <a href="#fund" className="nav-link">О ПРОЕКТЕ</a>
        </nav>
      </div>

      {/* Боковое меню (мобильное и планшетное) */}
      <nav className={`main-header-sidebar ${menuOpen ? "open" : ""}`}>
        <button className="close-menu" onClick={() => setMenuOpen(false)}>✖</button>
        <ul>
          <li><a href="#about" onClick={() => setMenuOpen(false)}>ФИЗИКА ДЛЯ ВСЕХ</a></li>
          <li><a href="#subjects" onClick={() => setMenuOpen(false)}>ШКОЛЬНЫЙ КУРС</a></li>
          <li><a href="#journal" onClick={() => setMenuOpen(false)}>ЕГЭ/ОГЭ</a></li>
          <li><a href="#subjects" onClick={() => setMenuOpen(false)}>УНИВЕРСИТЕТ</a></li>
          <li><a href="#fund" onClick={() => setMenuOpen(false)}>О ПРОЕКТЕ</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default MainHeader;
