import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import questions_ege_5_1 from "../../../../data/ege_questions/questions_ege_5_1";
import EgeContentSwitcher from "../../../../components/EgeContentSwitcher";
import PhysicsPartOneSheet from "../../../../components/PhysicsPartOneSheet";
import FooterMain from "../../../../components/main_components/FooterMain";
import CollapsibleCalculator from "../../../../components/CollapsibleCalculator";
import EgeTimer from "../../../../components/EgeTimer";

// Заглушки для остальных частей
const PlaceholderComponent = ({ text }) => (
  <div className="under-construction">
    <div className="under-construction-box">
      <p>{text}</p>
    </div>
  </div>
);

function PhysicsTaskSheet_5_1() {
  const [answers, setAnswers] = useState({});

  return (
    <div>
      <EgeContentSwitcher
        Component1={PhysicsPartOneSheet}
        Component2={PlaceholderComponent}
        Component3={PlaceholderComponent}
        Component4={PlaceholderComponent}
        sharedProps={{
          questions: questions_ege_5_1,
          answers,
          setAnswers,
          text: "Раздел в разработке"
        }}
      />
      <CollapsibleCalculator />
      <EgeTimer initialTime={10800} />
      <FooterMain />
    </div>
  );
}

export default PhysicsTaskSheet_5_1;
