
import PropTypes from "prop-types";
import React, { useEffect } from "react";

const ContentHead = ({ title }) => {

  return (
	<div className="text-head">
		<h1>{title}</h1>
		<hr className="hr-vertical-lines" />
	</div>
  );
};

export default ContentHead;