
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { initializeAnimations, loadAnimationOrImage } from '../assets/script';

const InteractiveMainContentWrapper = ({ interactives = [] }) => {
  return (
    <div className="interactive-main-content-wrapper">	
	  <div className="tests-label" id="tests-section">
		<img src="/img/interact-ico.svg" alt="Interactive Icon" />
		<span>ИНТЕРАКТИВЫ</span>
	  </div>
      <div className="interactive-grid">
        {interactives.length > 0 ? (
          interactives.map((interactive, index) => (
            <a
              key={index}
              href={interactive.link}
              target="_blank"
              rel="noopener noreferrer"
              className="interactive-tile"
            >
              <div className="interactive-image-container">
                <img
                  src={interactive.image}
                  alt={interactive.title}
                  className="interactive-image"
                />
              </div>
              <div className="interactive-title-container">
                <h2 className="interactive-tile-title">{interactive.title}</h2>
              </div>
            </a>
          ))
        ) : (
          <p className="no-interactives">Интерактивы пока отсутствуют.</p>
        )}
      </div>
	  <hr className="hr-vertical-lines" />
    </div>
  );
};

InteractiveMainContentWrapper.propTypes = {
  interactives: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    })
  ),
};

export default InteractiveMainContentWrapper;