import React, { useState, useRef, useEffect } from 'react';
import PropTypes from "prop-types";
import "../../assets/styles/main_components/TabSwitcher.css";

function TabSwitcher({ tabs, content, title }) {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuTop, setMenuTop] = useState(0);
  const menuButtonRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuButtonRef.current) {
      const rect = menuButtonRef.current.getBoundingClientRect();
      setMenuTop(rect.bottom + window.scrollY + 10); // 10px отступ от кнопки
    }
  }, [menuOpen]);

  return (
    <div className="tab-switcher-wrapper">
      <div className="tab-switcher-head">
        {/* Заголовок */}
        {title && <h2 className="tab-switcher-title">{title}</h2>}

        {/* Вкладки для десктопа */}
        <div className="tab-buttons desktop">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`tab-button ${activeTab === tab ? "active" : ""}`}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* меню для мобильных устройств */}
        <div
          className={`menu-container mobile ${menuOpen ? "change" : ""}`}
          onClick={toggleMenu}
          ref={menuButtonRef}
        >
          <div className="menu-bar bar1"></div>
          <p className={`menu-label ${menuOpen ? "hidden" : ""}`}>{activeTab}</p>
          <div className="menu-bar bar3"></div>
        </div>
      </div>

      <ul
        className={`mobile-menu ${menuOpen ? "menu-visible" : "menu-hidden"}`}
        style={{ top: `${menuTop}px` }}
      >
        {tabs.map((tab) => (
          <li key={tab}>
            <button onClick={() => { setActiveTab(tab); setMenuOpen(false); }}>
              {tab}
            </button>
          </li>
        ))}
      </ul>

      {/* Контент */}
      <div className="tab-content">
        {content[activeTab]}
      </div>
    </div>
  );
}

TabSwitcher.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  content: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default TabSwitcher;
