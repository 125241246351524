import React from "react";

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-col">
                    <img src="/img/logo_footer.svg" alt="Логотип" className="footer-logo" />
                    <ul>
                        <li><a href="#">О проекте</a></li>
                        <li><a href="#">Стать автором</a></li>
                        <li><a href="#">Поддержать проект</a></li>
                        <li><a href="#">Связаться</a></li>
                    </ul>
                </div>
                <div className="footer-col footer-donation-col">
                    <h3>НА ЗАРПЛАТЫ НИЩИМ ЭНТУЗИАСТАМ</h3>
                    <p>❤️‍🔥 💵💵 🙏 😽</p>
                    <form action="https://www.example.com/donate" method="post" className="donation-form">
                        <label htmlFor="donation-amount">Сумма пожертвования (в Руб.):</label>
                        <input 
                            type="number" 
                            id="donation-amount" 
                            name="amount" 
                            placeholder="Введите сумму" 
                            min="1" 
                            required 
                        />
                        
                        <label htmlFor="donor-email">Ваш email:</label>
                        <input 
                            type="email" 
                            id="donor-email" 
                            name="email" 
                            placeholder="Введите ваш email" 
                            required 
                        />
                        
                        {/* Кнопки выбора типа пожертвования */}
                        <div className="donation-buttons">
                            <button type="submit">РАЗОВОЕ ПОЖЕРТВОВАНИЕ</button>
                            <button type="submit">РЕГУЛЯРНЫЕ ПОЖЕРТВОВАНИЯ</button>
                        </div>
                    </form>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
