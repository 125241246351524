
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { initializeAnimations, loadAnimationOrImage } from '../assets/script';

const ContentWrapper = ({ title, videoSrc, sections }) => {
  useEffect(() => {
    // Находим все элементы с классом 'lottie-animation-container'
    const animationContainers = document.querySelectorAll('.lottie-animation-container');
    
    // Собираем их id и передаем в функцию инициализации
    const animationIds = Array.from(animationContainers).map(container => container.id);
    initializeAnimations(animationIds);
  }, []);

  return (
    <div id="content-wrapper">
      {/* Видеоурок */}
      <div className="video-container">
        <div id="video-section" className="video-label">
          <img src="/img/video.svg" alt="Video Icon" />
          <span>ВИДЕОУРОК</span>
        </div>
        <div className="video-wrapper">
          <div className="video-responsive">
            <iframe
              src={videoSrc}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <hr className="hr-vertical-lines" style={{ marginTop: '70px' }} />
      </div>

      {/* Секции */}
      <div className="text-block-container">
        <div id="conspect-section" className="text-label">
          <img src="/img/conspect.svg" alt="Conspect Icon" />
          <span>КОНСПЕКТ</span>
        </div>
        <div className="toc-container">
          <section className="toc-wrapper">
            <h1>Содержание конспекта</h1>
            <ol className="toc">
              {sections.map((section, index) => (
                <li key={index}>
                  <a href="#" data-target={section.target}>
                    {section.title}
                  </a>
                </li>
              ))}
            </ol>
          </section>
        </div>

		{sections.map((section, index) => (
		  <React.Fragment key={index}>
			<hr className="delimeter-line" style={{ marginTop: '40px' }} />
			<div id={section.target.replace('#', '')} className="icon-paragraph">
			  <img src="/img/paragraph.svg" alt="Paragraph" className="icon-image" />
			  <span className="icon-text">{section.title}</span>
			</div>

			{/* Отображаем блок definition, если он существует */}
			{section.definition && (
			  <div className="definition-box">
				<p>{section.definition}</p>
			  </div>
			)}

			{/* Отображаем блок text, если он существует */}
			{section.text && (
			  <div className="main-text-block">
				{section.text}
			  </div>
			)}

			{/* Анимация или изображение */}
			<div
			  id={section.animationId}
			  className={
				section.animationPath.endsWith('.json')
				  ? 'lottie-animation-container'
				  : 'image-container'
			  }
			  data-animation-path={section.animationPath}
			>
			  {section.animationPath.endsWith('.json') ? null : (
				<img
				  src={section.animationPath}
				  alt="Animation or Illustration"
				  className="animation-image"
				/>
			  )}
			</div>
		  </React.Fragment>
		))}
      </div>
      <hr className="hr-vertical-lines" style={{ margin: '0' }} />
    </div>
  );
};

export default ContentWrapper;