export function shuffleArrayWithIndexes(array) {
    const indexedArray = array.map((item, index) => ({ ...item, originalIndex: index }));
    for (let i = indexedArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [indexedArray[i], indexedArray[j]] = [indexedArray[j], indexedArray[i]];
    }
    return indexedArray;
}

export function handleDragStart(e) {
    e.dataTransfer.setData("text/plain", e.target.dataset.index);
}

export function handleDragOver(e) {
    e.preventDefault();
}

export function handleDrop(e, shuffledAnswers, setShuffledAnswers) {
    e.preventDefault();
    const draggedIndex = e.dataTransfer.getData("text/plain");
    const targetIndex = e.target.dataset.index;

    const reorderedAnswers = [...shuffledAnswers];
    [reorderedAnswers[draggedIndex], reorderedAnswers[targetIndex]] = [
        reorderedAnswers[targetIndex],
        reorderedAnswers[draggedIndex],
    ];
    setShuffledAnswers(reorderedAnswers);
}

export function finishTest() {
    alert("Тест завершён!");
}

export function checkAnswer(question, shuffledAnswers) {
    let isCorrect = false;

    if (question.type === "multiple") {
        const selectedAnswers = shuffledAnswers.filter((answer) => answer.selected);
        isCorrect =
            selectedAnswers.every((answer) => answer.correct) &&
            selectedAnswers.length ===
                question.answers.filter((answer) => answer.correct).length;
    } else if (question.type === "single") {
        const selectedAnswer = shuffledAnswers.find((answer) => answer.selected);
        isCorrect = selectedAnswer?.correct || false;
    } else if (question.type === "match") {
        isCorrect = true;
        shuffledAnswers.forEach((pair, i) => {
            const selectedValue = pair.selectedValue || "";
            const originalIndex = pair.originalIndex;
            if (selectedValue !== question.answers[originalIndex].correct) {
                isCorrect = false;
            }
        });
    } else if (question.type === "order") {
        const currentOrder = shuffledAnswers.map((item) => item.originalIndex);
        isCorrect = currentOrder.every(
            (val, index) => val === question.correctOrder[index]
        );
    } else if (question.type === "text") {
        const userAnswer = question.userAnswer?.trim().toLowerCase() || "";
        const correctAnswer = question.correctAnswer?.trim().toLowerCase() || "";

        if (!correctAnswer) {
            console.error("Correct answer is missing for text question");
        }

        isCorrect = userAnswer === correctAnswer ? true : false;
    }

    return isCorrect;
}


export function isSubmitButtonEnabled(question, shuffledAnswers) {
    if (question.type === "multiple") {
        return Array.isArray(shuffledAnswers) && shuffledAnswers.some(answer => answer.selected);
    }
    if (question.type === "single") {
        return Array.isArray(shuffledAnswers) && shuffledAnswers.some(answer => answer.selected);
    }
    if (question.type === "text") {
        return typeof question.userAnswer === "string" && question.userAnswer.trim().length > 0;
    }
    if (question.type === "match" || question.type === "order") {
        return true; // Активна по умолчанию
    }
    return false;
}

export function resetAnswers(question) {
    if (question.type === "multiple" || question.type === "single") {
        question.answers.forEach((answer) => {
            answer.selected = false;
        });
    } else if (question.type === "text") {
        question.userAnswer = "";
    }
}