
import React, { useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import Sidebar from "../../../components/Sidebar";
import BottomMenu from "../../../components/BottomMenu";
import LeftMenuWrapper from "../../../components/LeftMenuWrapper";
import SliderContainer from "../../../components/SliderContainer";
import ButtonContainer from "../../../components/ButtonContainer";
import ExtraButtonsContainer from "../../../components/ExtraButtonsContainer";
import ContentWrapper from "../../../components/ContentWrapper";
import ScrollTopButton from "../../../components/main_components/ScrollTopButton";
import ContentHead from "../../../components/ContentHead";
import AnimationRenderer from "../../../components/AnimationRenderer";
import TextBlock from "../../../components/TextBlock";
import DefinitionBlock from "../../../components/DefinitionBlock";
import TitleBlock from "../../../components/TitleBlock";
import TableOfContents from "../../../components/TableOfContents";
import VideoBlock from "../../../components/VideoBlock";
import DelimeterLine from "../../../components/DelimeterLine";

function Inertia_lecture_7_cl() {
	
	const slidesData = [
        { image: "/img/pic-1.png", text: "Текст для слайда 1" },
        { image: "/img/pic-2.png", text: "Текст для слайда 2" },
        { image: "/img/pic-3.png", text: "Текст для слайда 3" },
    ];
	
	  const sections = [
		{
		  title: "Взаимодействие тел",
		  target: "#interaction-section",
		},
		{
		  title: "Инерция",
		  target: "#inertia-section",
		},
		{
		  title: "Инертность тел",
		  target: "#inertness-section",
		},
		{
		  title: "Масса, как мера инертности",
		  target: "#mass-section",
		},
	  ];

 
	return (
	<div>
		<Navbar />
		<Sidebar />
		<LeftMenuWrapper />
		<ContentHead
			title="Инерция"
		/>
		<VideoBlock
			videoSrc="https://www.youtube.com/embed/IEkMUnowjUI"
		/>
		<TableOfContents sections={sections} title="Содержание конспекта:" />
		<TitleBlock title="Взаимодействие тел" target="#interaction-section" />
		<DefinitionBlock definition=<p>В природе не существует одностороннего воздействия. Между телами всегда
										происходит <u>ВЗАИМОдействие</u> - первое тело воздействует на второе
										так же, как второе тело воздействует на первое.</p> />
		<TextBlock text=<p>
							Движение - это изменение положения тела. Когда тело не движется, его
							скорость равна нулю.<br /><br />
							Для того, чтобы привести в движение тело, находящееся в покое, то есть
							изменить его положение, нужно внешнее воздействие со стороны другого
							тела. Нужно приложить силу: пнуть, толкнуть, поднять, потянуть
							что-либо.<br /><br />
							Опыт с тележками показывает, как в первом случае одиночная
							тележка после распрямления упругой пластины остаётся на месте. Причина,
							по которой тележка не сдвинулась с места - это отсутствие
							взаимодействия с другими телами.<br /><br />
							Во втором опыте две тележки подогнаны друг к другу вплотную, и после распрямления упругой пластины, обе
							тележки приходят в движение. Тележки пришли в движение потому что
							произошло взаимодействие. Левая тележка воздействовала на правую через
							пластину ровно с той же силой, что и правая на левую.<br /><br />
							Поэтому их скорости и пройденные ими пути равны между собой.
						  </p> />
		<AnimationRenderer
		  animationPath="/img/interaction.json"
		  animationId="lottie-animation-1"
		/>
		<TitleBlock title="Инерция" target="#inertia-section" />
		<DefinitionBlock definition=<p><b>Инерция</b> — явление сохранения телом неизменной скорости движения при отсутствии внешнего воздействия.</p> />
		<TextBlock text=<p><b>Инерция</b> — явление сохранения телом неизменной скорости движения при отсутствии внешнего воздействия.</p> />
		<AnimationRenderer
		  animationPath="/img/zhelob.json"
		  animationId="lottie-animation-2"
		/>
		<TitleBlock title="Инертность тел" target="#inertness-section" />
		<DefinitionBlock definition=<p><b>Инертность тела</b> — это способность тела сопротивляться изменению скорости движения в присутствии внешнего воздействия.</p> />
		<TextBlock text=<p>Проще говоря, это то, насколько сложно сдвинуть тело с места, если оно не движется. Или остановить тело, если оно движется.<br /><br /></p> />
		<AnimationRenderer
		  animationPath="/img/inertness.svg"
		  animationId="lottie-animation-3"
		/>
		<TitleBlock title="Масса, как мера инертности" target="#mass-section" />
		<DefinitionBlock definition=<p><b>Масса</b> — скалярная физическая величина, которая служит мерой инертности тела, то есть его способности сопротивляться 
									изменению скорости в присутствии внешнего воздействия.</p> />
		<TextBlock text=<p><b>Инерция</b> — явление сохранения телом неизменной скорости движения при отсутствии внешнего воздействия.</p> />
		<AnimationRenderer
		  animationPath="/img/inertia-mass.json"
		  animationId="lottie-animation-4"
		/>
		<DelimeterLine />
		<SliderContainer slidesData={slidesData} />
		<ExtraButtonsContainer
			interactiveLink="/interactive-trainer"
			testLink="/courses/physics/7-class/tests/inertia/"
		/>
		<ButtonContainer
			prevButtonText="Равномерное и неравномерное движение"
			nextButtonText="Ускорение"
			prevPageLink="/uniform-motion"
			nextPageLink="/acceleration"
		/>
		<BottomMenu
			videoId="video-section"
			conspectId="conspect-section"
			interactiveLink="/interactive-trainer"
			testLink="/test"
		/>
		<Footer />
		<ScrollTopButton />
	</div>
	);
}

export default Inertia_lecture_7_cl;
