import React from "react";
import PropTypes from "prop-types";

const TableOfContents = ({ sections, title }) => {
    if (!sections || sections.length === 0) return null;

    const handleScroll = (event, target) => {
        event.preventDefault();
        const targetElement = document.getElementById(target.replace("#", ""));
        const offset = 100; // Смещение для фиксированного меню
        if (targetElement) {
            const offsetPosition =
                targetElement.getBoundingClientRect().top +
                window.pageYOffset -
                offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="main-toc-container">
            <div id="conspect-section" className="text-label">
                <img src="/img/conspect.svg" alt="Conspect Icon" />
                <span>КОНСПЕКТ</span>
            </div>
            <div className="toc-container">
                <section className="toc-wrapper">
                    {title && <h1>{title}</h1>}
                    <ol className="toc">
                        {sections.map((section, index) => (
                            <li key={index}>
                                <a
                                    href={section.target}
                                    onClick={(e) => handleScroll(e, section.target)}
                                >
                                    {section.title}
                                </a>
                            </li>
                        ))}
                    </ol>
                </section>
            </div>
        </div>
    );
};

TableOfContents.propTypes = {
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            target: PropTypes.string.isRequired,
        })
    ).isRequired,
    title: PropTypes.string,
};

TableOfContents.defaultProps = {
    title: "Содержание",
};

export default TableOfContents;
