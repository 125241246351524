
import React, { useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import Sidebar from "../../../components/Sidebar";
import BottomMenu from "../../../components/BottomMenu";
import LeftMenuWrapper from "../../../components/LeftMenuWrapper";
import SliderContainer from "../../../components/SliderContainer";
import ButtonContainer from "../../../components/ButtonContainer";
import ExtraButtonsContainer from "../../../components/ExtraButtonsContainer";
import ContentWrapper from "../../../components/ContentWrapper";
import ScrollTopButton from "../../../components/main_components/ScrollTopButton";

function Trajectory_path_and_time_of_movement_lecture_7_cl() {
	
	const slidesData = [
        { image: "/img/pic-1.png", text: "Текст для слайда 1" },
        { image: "/img/pic-2.png", text: "Текст для слайда 2" },
        { image: "/img/pic-3.png", text: "Текст для слайда 3" },
    ];
	
    const sections = [
        {
            title: "Перемещение",
            target: "#interaction-section",
			definition: (
			  <p>
				<b>Перемещение</b> — направленный отрезок (вектор), соединяющий начальное и конечное положение тела.
			  </p>
			),
			text: (
			  <p>
				Движение - это изменение положения тела. Когда тело не движется, его
				скорость равна нулю.<br /><br />
				Для того, чтобы привести в движение тело, находящееся в покое, то есть
				изменить его положение, нужно внешнее воздействие со стороны другого
				тела. Нужно приложить силу: пнуть, толкнуть, поднять, потянуть
				что-либо.<br /><br />
				Опыт с тележками показывает, как в первом случае одиночная
				тележка после распрямления упругой пластины остаётся на месте. Причина,
				по которой тележка не сдвинулась с места - это отсутствие
				взаимодействия с другими телами.<br /><br />
				Во втором опыте две тележки подогнаны друг к другу вплотную, и после распрямления упругой пластины, обе
				тележки приходят в движение. Тележки пришли в движение потому что
				произошло взаимодействие. Левая тележка воздействовала на правую через
				пластину ровно с той же силой, что и правая на левую.<br /><br />
				Поэтому их скорости и пройденные ими пути равны между собой.
			  </p>
			),
			animationPath: "/img/class-7/path-and-trajectory/movement.svg",
            animationId: "lottie-animation-1",
        },
        {
            title: "Траектория движения",
            target: "#inertia-section",
            definition: <p><b>Траектория движения</b> — линия, вдоль которой движется тело.</p>,
            text: <p><b>Инерция</b> — явление сохранения телом неизменной скорости движения при отсутствии внешнего воздействия.</p>,
            animationPath: "/img/class-7/path-and-trajectory/trajectory.svg",
            animationId: "lottie-animation-2",
        },
        {
            title: "Путь",
            target: "#inertness-section",
            definition: <p><b>Путь</b> — длина траектории, по которой движется тело в течение некоторого промежутка времени.</p>,
			text: (
			  <p>
				Проще говоря, это то, насколько сложно сдвинуть тело с места, если оно не движется. Или остановить тело, если оно движется.<br /><br />
				
			  </p>
			),
            animationPath: "/img/class-7/path-and-trajectory/path.svg",
            animationId: "lottie-animation-3",
        },
        {
            title: "Из Москвы в Сочи",
            target: "#mass-section",
            definition: null,
            text: <p><b>Инерция</b> — явление сохранения телом неизменной скорости движения при отсутствии внешнего воздействия.</p>,
            animationPath: "/img/class-7/path-and-trajectory/moscow-sochi.json",
            animationId: "lottie-animation-4",
        },
    ];    

 
	return (
	<div>
		<Navbar />
		<Sidebar />
		<LeftMenuWrapper />
		<ContentWrapper
			title="Траектория, путь и перемещение"
			videoSrc="https://www.youtube.com/embed/SMHJg3EAey4"
			sections={sections}
		/>
		<SliderContainer slidesData={slidesData} />
		<ExtraButtonsContainer
			interactiveLink="/interactive-trainer"
			testLink="/test"
		/>
		<ButtonContainer
			prevButtonText="Механическое движение"
			nextButtonText="Скорость"
			prevPageLink="/uniform-motion"
			nextPageLink="/acceleration"
		/>
		<BottomMenu
			videoId="video-section"
			conspectId="conspect-section"
			interactiveLink="/interactive-trainer"
			testLink="/test"
		/>
		<Footer />
		<ScrollTopButton />
	</div>
	);
}

export default Trajectory_path_and_time_of_movement_lecture_7_cl;