import React, { useRef, useState, useEffect } from 'react';
import "../../assets/styles/school/Physics_class_7_content.css";

function Tooltip({ text, isActive }) {
  return (
    <div className={`tooltip ${isActive ? "active" : "disabled"}`}>
      {text}
    </div>
  );
}

function Physics_class_7_content() {
  const [tooltip, setTooltip] = useState({
    text: "",
    x: 0,
    y: 0,
    visible: false,
  });

  const showTooltip = (text, event) => {
    const target = event.currentTarget.getBoundingClientRect();
    setTooltip({
      text,
      x: target.left + target.width / 2, // Центр элемента по горизонтали
      y: target.top, // Верх элемента
      visible: true,
    });
  };

  const hideTooltip = () => {
    setTooltip({ ...tooltip, visible: false });
  };
  
// Рефы для заголовков
  const sectionsRefs = {
    introduction: useRef(null),
    matter: useRef(null),
    forces: useRef(null),
    motion: useRef(null),
    interaction: useRef(null),
    pressure: useRef(null),
    work: useRef(null),
  };

	// Реф для меню
  const menuRef = useRef(null);

  const scrollToSection = (section) => {
    const headerHeight = 150; // Укажите высоту MainHeader
    const sectionElement = sectionsRefs[section]?.current;

    if (sectionElement) {
      const offsetTop = sectionElement.offsetTop - headerHeight;

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    const headerHeight = 150;
    const menuElement = menuRef.current;

    if (menuElement) {
      if (window.scrollY > headerHeight) {
        menuElement.style.position = "fixed";
        menuElement.style.top = `${headerHeight}px`;
      } else {
        menuElement.style.position = "absolute";
        menuElement.style.top = "0px";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <div className="content-page-wrapper">
      {/* Навигационный блок */}
      <div className="content-navigation-menu">
        <ul>
          <li onClick={() => scrollToSection('introduction')}>ВВЕДЕНИЕ</li>
		  <hr className="hr-pale-line" />
          <li onClick={() => scrollToSection('matter')}>СТРОЕНИЕ ВЕЩЕСТВА</li>
		  <hr className="hr-pale-line" />
          <li onClick={() => scrollToSection('forces')}>СИЛЫ В ПРИРОДЕ</li>
		  <hr className="hr-pale-line" />
          <li onClick={() => scrollToSection('motion')}>МЕХАНИЧЕСКОЕ ДВИЖЕНИЕ</li>
		  <hr className="hr-pale-line" />
          <li onClick={() => scrollToSection('interaction')}>ВЗАИМОДЕЙСТВИЕ ТЕЛ</li>
		  <hr className="hr-pale-line" />
          <li onClick={() => scrollToSection('pressure')}>ДАВЛЕНИЕ ТВЁРДЫХ ТЕЛ, ЖИДКОСТЕЙ И ГАЗОВ</li>
		  <hr className="hr-pale-line" />
          <li onClick={() => scrollToSection('work')}>РАБОТА И МОЩНОСТЬ</li>
        </ul>
      </div>

      {/* Содержимое страницы */}
      <div className="content-page-list">
		<div className="content-menu-block" ref={sectionsRefs.introduction}>
        {/* ВВЕДЕНИЕ */}
        <li className="content-submenu-title">ВВЕДЕНИЕ</li>
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/introduction">
				  Что такое физика
				</a>
			  ) : (
				<span>Что такое физика</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/introduction">
				  Наблюдения, опыты, гипотезы
				</a>
			  ) : (
				<span>Наблюдения, опыты, гипотезы</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/measurements">
				  Физические величины, единицы измерения
				</a>
			  ) : (
				<span>Физические величины, единицы измерения</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/instruments">
				  Приборы, точность, погрешность
				</a>
			  ) : (
				<span>Приборы, точность, погрешность</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		</li>
		</div>
		
		<div className="content-menu-block" ref={sectionsRefs.matter}>
        {/* СТРОЕНИЕ ВЕЩЕСТВА */}
        <li className="content-submenu-title">СТРОЕНИЕ ВЕЩЕСТВА</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/matter">
				  Вещество и материя
				</a>
			  ) : (
				<span>Вещество и материя</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/atom">
				  Атом
				</a>
			  ) : (
				<span>Атом</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/molecule">
				  Молекула
				</a>
			  ) : (
				<span>Молекула</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/interactions">
				  Взаимодействие молекул
				</a>
			  ) : (
				<span>Взаимодействие молекул</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/diffusion">
				  Диффузия
				</a>
			  ) : (
				<span>Диффузия</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/temperature">
				  Температура, давление и плотность вещества
				</a>
			  ) : (
				<span>Температура, давление и плотность вещества</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/aggregate-states">
				  Агрегатные состояния вещества
				</a>
			  ) : (
				<span>Агрегатные состояния вещества</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		</li>
		</div>

		<div className="content-menu-block" ref={sectionsRefs.forces}>
        {/* СИЛЫ В ПРИРОДЕ */}
        <li className="content-submenu-title">СИЛЫ В ПРИРОДЕ</li>
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/forces">
				  Четыре главных силы
				</a>
			  ) : (
				<span>Четыре главных силы</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		</li>
		</div>

		<div className="content-menu-block" ref={sectionsRefs.motion}>
        {/* МЕХАНИЧЕСКОЕ ДВИЖЕНИЕ */}
        <li className="content-submenu-title">МЕХАНИЧЕСКОЕ ДВИЖЕНИЕ</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/mechanical-motion">
				  Механическое движение
				</a>
			  ) : (
				<span>Механическое движение</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${true ? "active" : "disabled"}`}>
			  {true ? (
				<a href="/courses/physics/7-class/lecture/trajectory-path-and-time-of-movement">
				  Траектория, путь и время движения
				</a>
			  ) : (
				<span>Траектория, путь и время движения</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {true ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/trajectory-path-and-time-of-movement" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {true ? (
				<a href="/courses/physics/7-class/lecture/trajectory-path-and-time-of-movement" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {true ? (
				<a href="/courses/physics/7-class/tests/trajectory-path-and-time-of-movement" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/speed">
				  Скорость
				</a>
			  ) : (
				<span>Скорость</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/uniform-motion">
				  Равномерное и неравномерное движение
				</a>
			  ) : (
				<span>Равномерное и неравномерное движение</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${true ? "active" : "disabled"}`}>
			  {true ? (
				<a href="/courses/physics/7-class/lecture/inertia">
				  Инерция
				</a>
			  ) : (
				<span>Инерция</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {true ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/inertia" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {true ? (
				<a href="/courses/physics/7-class/lecture/inertia" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {true ? (
				<a href="/courses/physics/7-class/interactive/inertia" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {true ? (
				<a href="/courses/physics/7-class/tests/inertia" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/acceleration">
				  Ускорение
				</a>
			  ) : (
				<span>Ускорение</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/motion-graph">
				  График движения
				</a>
			  ) : (
				<span>График движения</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		</li>
		</div>

		<div className="content-menu-block" ref={sectionsRefs.interaction}>
        {/* ВЗАИМОДЕЙСТВИЕ ТЕЛ */}
        <li className="content-submenu-title">ВЗАИМОДЕЙСТВИЕ ТЕЛ</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/force">
				  Сила
				</a>
			  ) : (
				<span>Сила</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/gravity">
				  Сила тяжести
				</a>
			  ) : (
				<span>Сила тяжести</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/weight">
				  Вес тела и невесомость. Реакция опоры
				</a>
			  ) : (
				<span>Вес тела и невесомость. Реакция опоры</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/elasticity">
				  Сила упругости. Закон Гука
				</a>
			  ) : (
				<span>Сила упругости. Закон Гука</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/friction">
				  Сила трения
				</a>
			  ) : (
				<span>Сила трения</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/resultant-force">
				  Равнодействующая сил
				</a>
			  ) : (
				<span>Равнодействующая сил</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		</li>
		</div>

		<div className="content-menu-block" ref={sectionsRefs.pressure}>
        {/* ДАВЛЕНИЕ ТВЁРДЫХ ТЕЛ, ЖИДКОСТЕЙ И ГАЗОВ */}
        <li className="content-submenu-title">ДАВЛЕНИЕ ТВЁРДЫХ ТЕЛ, ЖИДКОСТЕЙ И ГАЗОВ</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/pressure">
				  Давление
				</a>
			  ) : (
				<span>Давление</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/solid-pressure">
				  Передача давления твёрдыми телами
				</a>
			  ) : (
				<span>Передача давления твёрдыми телами</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/liquid-pressure">
				  Передача давления твёрдыми телами
				</a>
			  ) : (
				<span>Передача давления жидкостями и газами. Закон Паскаля</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/hydrostatic-pressure">
				  Гидростатическое давление
				</a>
			  ) : (
				<span>Гидростатическое давление</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/communicating-vessels">
				  Сообщающиеся сосуды
				</a>
			  ) : (
				<span>Сообщающиеся сосуды</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/atmospheric-pressure">
				  Атмосферное давление
				</a>
			  ) : (
				<span>Атмосферное давление</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/barometers">
				  Барометры
				</a>
			  ) : (
				<span>Барометры</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/manometers">
				  Манометры
				</a>
			  ) : (
				<span>Манометры</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/hydraulic-devices">
				  Гидравлический пресс
				</a>
			  ) : (
				<span>Гидравлический пресс</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/archimedes">
				  Сила Архимеда
				</a>
			  ) : (
				<span>Сила Архимеда</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/play" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/text" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		</li>
		</div>

		<div className="content-menu-block" ref={sectionsRefs.work}>
        {/* РАБОТА И МОЩНОСТЬ */}
        <li className="content-submenu-title">РАБОТА И МОЩНОСТЬ</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/work">
				  Механическая работа
				</a>
			  ) : (
				<span>Механическая работа</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/work" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/work" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/power">
				  Мощность
				</a>
			  ) : (
				<span>Мощность</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/power" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/power" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/simple-machines">
				  Простые механизмы
				</a>
			  ) : (
				<span>Простые механизмы</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/simple-machines" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/simple-machines" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/leverage">
				  Принцип рычага
				</a>
			  ) : (
				<span>Принцип рычага</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/leverage" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/leverage" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/force-moment">
				  Момент силы
				</a>
			  ) : (
				<span>Момент силы</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/leverage" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/leverage" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/block-system">
				  Блоки
				</a>
			  ) : (
				<span>Блоки</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/leverage" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/leverage" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/golden-rule">
				  Золотое правило механики
				</a>
			  ) : (
				<span>Золотое правило механики</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/golden-rule" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/golden-rule" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/center-of-gravity">
				  Центр тяжести
				</a>
			  ) : (
				<span>Центр тяжести</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/center-of-gravity" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/center-of-gravity" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/efficiency">
				  КПД механизма
				</a>
			  ) : (
				<span>КПД механизма</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/efficiency" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/efficiency" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/energy">
				  Энергия
				</a>
			  ) : (
				<span>Энергия</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/energy" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/energy" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/energy-conversion">
				  Преобразование энергии
				</a>
			  ) : (
				<span>Преобразование энергии</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/energy-conversion" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/energy-conversion" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		  <hr className="hr-pale-line" />
		</li>
		
		<li>
		  <div className="content-item">
			<div className={`content-link ${false ? "active" : "disabled"}`}>
			  {false ? (
				<a href="/courses/physics/7-class/lecture/energy-fluence">
				  Энергия жидкости
				</a>
			  ) : (
				<span>Энергия жидкости</span>
			  )}
			</div>
			<div className="icon-container">
			  {/** Активная иконка с href */}
			  {false ? ( // Если активна, заменить false на условие
				<a href="/courses/physics/7-class/lecture/fluid-energy" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Видеоурок", e)}
				   onMouseLeave={hideTooltip}
				>
				  <img src="/img/video-play-active.svg" alt="Play" className="icon" />
				</a>
			  ) : (
				<img src="/img/video-play-disabled.svg" 
				 alt="Play" 
				 className="icon disabled" 
				 onMouseEnter={(e) => showTooltip("Видеоурок в разработке", e)}
				 onMouseLeave={hideTooltip}/>
			  )}

			  {false ? (
				<a href="/courses/physics/7-class/lecture/fluid-energy" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Конспект", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/text-conspect-active.svg" alt="Text" className="icon" />
				</a>
			  ) : (
				<img src="/img/text-conspect-disabled.svg" 
				 alt="Text" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Конспект в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/exercise" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Интерактивный тренажер", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/exercise-active.svg" alt="Exercise" className="icon" />
				</a>
			  ) : (
				<img src="/img/exercise-disabled.svg" 
				 alt="Exercise" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Интерактивный тренажер в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}

			  {false ? (
				<a href="/check" 
				   className="icon-link"
				   onMouseEnter={(e) => showTooltip("Тест", e)}
				   onMouseLeave={hideTooltip}>
				  <img src="/img/check-active.svg" alt="Check" className="icon" />
				</a>
			  ) : (
				<img src="/img/check-disabled.svg" 
				 alt="Check" 
				 className="icon disabled"
				 onMouseEnter={(e) => showTooltip("Тест в разработке", e)}
				 onMouseLeave={hideTooltip} />
			  )}
			</div>
		  </div>
		</li>
		</div>
      {tooltip.visible && (
        <div
          className="tooltip-container"
          style={{
            top: tooltip.y - 40, // Позиция над иконкой
            left: tooltip.x,
            transform: "translateX(-25%)", // Центрирование по горизонтали
          }}
        >
          <Tooltip text={tooltip.text} isActive={tooltip.text !== "Text is unavailable"} />
        </div>
      )}
      </div>
    </div>
  );
};

export default Physics_class_7_content;
