import React from "react";
import PropTypes from "prop-types";

const DelimeterLine = () => {
	
  return (
    <div className="title-container">
	<hr className="hr-vertical-lines" style={{ margin: '0px' }} />
    </div>
  );
};

export default DelimeterLine;