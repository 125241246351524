
import React, { useState, useEffect } from "react";

const SliderContainer = ({ slidesData }) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const showSlide = (index) => {
        const totalSlides = slidesData.length;
        const newIndex = (index + totalSlides) % totalSlides;
        setCurrentSlideIndex(newIndex);
    };

    const nextSlide = () => showSlide(currentSlideIndex + 1);
    const prevSlide = () => showSlide(currentSlideIndex - 1);

    return (
        <div className="slider-container">
            <hr className="hr-vertical-lines" style={{ margin: 0 }} />
            <h2 className="slider-title">ГЛАВНОЕ</h2>
            <div className="slider-wrapper">
                {/* Slider Dots */}
                <div className="slider-dots">
                    {slidesData.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentSlideIndex ? "active" : ""}`}
                            onClick={() => showSlide(index)}
                        ></span>
                    ))}
                </div>

                {/* Slider */}
                <div id="slider">
                    {slidesData.map((slide, index) => (
                        <div
                            key={index}
                            className={`slide ${index === currentSlideIndex ? "active" : ""}`}
                        >
                            <div className="slide-content">
                                <div className="image-side">
                                    <img src={slide.image} alt={`Слайд ${index + 1}`} />
                                </div>
                                <div className="divider-line"></div>
                                <div className="text-side">
                                    <p>{slide.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Navigation Buttons */}
                <button className="prev" onClick={prevSlide}>
                    &#10094;
                </button>
                <button className="next" onClick={nextSlide}>
                    &#10095;
                </button>
            </div>
        </div>
    );
};

export default SliderContainer;
