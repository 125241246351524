import React from "react";
import { useCalculator } from "../assets/scripts/CollapsibleCalculator.js";
import "../assets/styles/CollapsibleCalculator.css";

function CollapsibleCalculator() {
  const {
    isOpen,
    expression,
    calculatorRef,
    setIsOpen,
    handleButtonClick,
    handleKeyDown
  } = useCalculator();

  return (
    <div className="calculator-container">
      {/* Иконка-кнопка */}
      <button className="calculator-toggle" onClick={() => setIsOpen(!isOpen)}>
        <div className="calculator-background">
          <img src="/img/calculator.svg" alt="Калькулятор" className="calculator-icon" />
          <span className="calculator-text">КАЛЬКУЛЯТОР</span>
        </div>
      </button>

      {/* Калькулятор */}
      {isOpen && (
        <div
          className="calculator"
          tabIndex={0}
          ref={calculatorRef}
          onKeyDown={handleKeyDown}
        >
          <div className="calculator-header">
            <span>Калькулятор</span>
            <button className="close-button" onClick={() => setIsOpen(false)}>
              ✖
            </button>
          </div>
          <div className="calculator-screen">{expression}</div>
          <div className="calculator-buttons">
            {["7", "8", "9", "/", "C"].map((btn) => (
              <button key={btn} onClick={() => handleButtonClick(btn)}>
                {btn}
              </button>
            ))}
            {["4", "5", "6", "*", "√"].map((btn) => (
              <button key={btn} onClick={() => handleButtonClick(btn)}>
                {btn}
              </button>
            ))}
            {["1", "2", "3", "-", "log"].map((btn) => (
              <button key={btn} onClick={() => handleButtonClick(btn)}>
                {btn}
              </button>
            ))}
            {["0", ".", "=", "+", "sin"].map((btn) => (
              <button key={btn} onClick={() => handleButtonClick(btn)}>
                {btn}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CollapsibleCalculator;
