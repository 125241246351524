import React from "react";
import { usePhysicsSheet } from "../assets/scripts/PhysicsPartOneSheet.js";
import "../assets/styles/PhysicsPartOneSheet.css";

function PhysicsPartOneSheet({ questions, answers, setAnswers }) {
  const { handleInputChange } = usePhysicsSheet(setAnswers);

  return (
    <div className="ege-task-sheet">
      <h1>ВАРИАНТ 5</h1>
      <h2>Часть 1</h2>
      <div className="instructions">
        Ответами к заданиям 1–20 являются число или последовательность цифр.
        Ответ запишите в поле ответа в тексте работы, а затем перенесите в
        БЛАНК ОТВЕТОВ № 1 справа от номера соответствующего задания, начиная с
        первой клеточки. Каждый символ пишите в отдельной клеточке в
        соответствии с приведённым в бланке образцом. Единицы измерения
        физических величин писать не нужно.
      </div>
      {questions.map((question, index) => (
        <div className="task" key={index}>
          <div className="task-number-box">
            <div className="task-number">{index + 1}</div>
          </div>
          <div className="task-content-box">
            <p>
              <span dangerouslySetInnerHTML={{ __html: question.text }} />
            </p>
            {question.image && (
              <div className="task-image">
                <img src={question.image} alt={`Задача ${index + 1}`} />
              </div>
            )}
            {question.list && (
              <ol className="task-list">
                {question.list.map((item, i) => (
                  <li key={i}>
                    <span dangerouslySetInnerHTML={{ __html: item }} />
                  </li>
                ))}
              </ol>
            )}
            {question.table && (
              <table className="task-table">
                <tbody>
                  {question.table.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>
                          <span dangerouslySetInnerHTML={{ __html: cell }} />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <div className="answer-box">
              <span>Ответ:</span>
              <div className="answer-line">
                <input
                  type="text"
                  value={answers[index] || ""}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              </div>
              {question.unit && (
                <span
                  className="unit"
                  dangerouslySetInnerHTML={{ __html: question.unit }}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PhysicsPartOneSheet;
