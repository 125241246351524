
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { initializeSectionNavigation } from "../assets/script";

const BottomMenu = ({ videoId, conspectId, interactiveLink, testLink }) => {
    useEffect(() => {
        const cleanupNavigation = initializeSectionNavigation(
            videoId,
            conspectId,
            "video-button",
            "conspect-button"
        );

        return () => {
            if (cleanupNavigation) cleanupNavigation();
        };
    }, [videoId, conspectId]);

    return (
        <div className="bottom-menu">
            <div className="menu-item video-button">
                <img src="/img/play.svg" alt="Play" />
                <span>Видео</span>
            </div>
            <div className="menu-item conspect-button">
                <img src="/img/text.svg" alt="Conspect" />
                <span>Конспект</span>
            </div>
            <div className="solid-line"></div>
            <Link to={interactiveLink} className="menu-item">
                <img src="/img/click.svg" alt="Click" />
                <span>Интерактив</span>
            </Link>
            <div className="solid-line"></div>
            <Link to={testLink} className="menu-item">
                <img src="/img/tick.svg" alt="Tick" />
                <span>Задания</span>
            </Link>
        </div>
    );
};

export default BottomMenu;
