import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  initializeSidebarToggle,
  initializeSidebarScroll,
  initializeSidebarSearch,
} from "../assets/script";

const Sidebar = () => {
  const location = useLocation(); // Получаем текущий URL

  useEffect(() => {
    // Initialize Sidebar Functions
    const cleanupToggle = initializeSidebarToggle("sidebar-toggle", "sidebar");
    const cleanupScroll = initializeSidebarScroll("sidebar", "sidebar-menu");
    const cleanupSearch = initializeSidebarSearch(
      "sidebar",
      "menu-search",
      "search-bar",
      ".sidebar-menu li"
    );

    return () => {
      // Cleanup on component unmount
      cleanupToggle?.();
      cleanupScroll?.();
      cleanupSearch?.();
    };
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar-title">СОДЕРЖАНИЕ</div>
      <ul className="sidebar-menu">
        <li className="no-filter">
          <a href="">
            <img src="/img/list_side.svg" alt="Side-list" />
            <span style={{ fontWeight: 500, fontSize: "16px" }}>СОДЕРЖАНИЕ</span>
          </a>
        </li>
        <li className="search-bar no-filter">
          <input type="text" id="menu-search" placeholder="Поиск по содержанию" />
        </li>
        <li><span className="submenu-title">ВВЕДЕНИЕ</span></li>
        <li><a href="/courses/physics/7-class/lecture/introduction" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/introduction" ? "active" : ""}`}>Что такое физика</a></li>
        <li><a href="/courses/physics/7-class/lecture/observations" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/observations" ? "active" : ""}`}>Наблюдения, опыты, гипотезы</a></li>
        <li><a href="/courses/physics/7-class/lecture/measurements" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/measurements" ? "active" : ""}`}>Физические величины, единицы измерения</a></li>
        <li><a href="/courses/physics/7-class/lecture/instruments" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/instruments" ? "active" : ""}`}>Приборы, точность, погрешность</a></li>
        <li><span className="submenu-title">СТРОЕНИЕ ВЕЩЕСТВА</span></li>
        <li><a href="/courses/physics/7-class/lecture/matter" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/matter" ? "active" : ""}`}>Вещество и материя</a></li>
        <li><a href="/courses/physics/7-class/lecture/atom" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/atom" ? "active" : ""}`}>Атом</a></li>
        <li><a href="/courses/physics/7-class/lecture/molecule" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/molecule" ? "active" : ""}`}>Молекула</a></li>
        <li><a href="/courses/physics/7-class/lecture/interactions" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/interactions" ? "active" : ""}`}>Взаимодействие молекул</a></li>
        <li><a href="/courses/physics/7-class/lecture/diffusion" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/diffusion" ? "active" : ""}`}>Диффузия</a></li>
        <li><a href="/courses/physics/7-class/lecture/temperature" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/temperature" ? "active" : ""}`}>Температура, давление и плотность вещества</a></li>
        <li><a href="/courses/physics/7-class/lecture/aggregate-states" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/aggregate-states" ? "active" : ""}`}>Агрегатные состояния вещества</a></li>
        <li><span className="submenu-title">СИЛЫ В ПРИРОДЕ</span></li>
        <li><a href="/courses/physics/7-class/lecture/forces" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/forces" ? "active" : ""}`}>Четыре главных силы</a></li>
        <li><span className="submenu-title">МЕХАНИЧЕСКОЕ ДВИЖЕНИЕ</span></li>
        <li><a href="/courses/physics/7-class/lecture/mechanical-motion" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/mechanical-motion" ? "active" : ""}`}>Механическое движение</a></li>
        <li><a href="/courses/physics/7-class/lecture/trajectory" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/trajectory" ? "active" : ""}`}>Траектория, путь и время движения</a></li>
        <li><a href="/courses/physics/7-class/lecture/speed" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/speed" ? "active" : ""}`}>Скорость</a></li>
        <li><a href="/courses/physics/7-class/lecture/uniform-motion" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/uniform-motion" ? "active" : ""}`}>Равномерное и неравномерное движение</a></li>
        <li><a href="/courses/physics/7-class/lecture/inertia" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/inertia" ? "active" : ""}`}>Инерция</a></li>
        <li><a href="/courses/physics/7-class/lecture/acceleration" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/acceleration" ? "active" : ""}`}>Ускорение</a></li>
        <li><a href="/courses/physics/7-class/lecture/motion-graph" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/motion-graph" ? "active" : ""}`}>График движения</a></li>
        <li><span className="submenu-title">ВЗАИМОДЕЙСТВИЕ ТЕЛ</span></li>
        <li><a href="/courses/physics/7-class/lecture/force" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/force" ? "active" : ""}`}>Сила</a></li>
        <li><a href="/courses/physics/7-class/lecture/gravity" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/gravity" ? "active" : ""}`}>Сила тяжести</a></li>
        <li><a href="/courses/physics/7-class/lecture/weight" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/weight" ? "active" : ""}`}>Вес тела и невесомость. Реакция опоры</a></li>
        <li><a href="/courses/physics/7-class/lecture/elasticity" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/elasticity" ? "active" : ""}`}>Сила упругости. Закон Гука</a></li>
        <li><a href="/courses/physics/7-class/lecture/friction" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/friction" ? "active" : ""}`}>Сила трения</a></li>
        <li><a href="/courses/physics/7-class/lecture/resultant-force" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/resultant-force" ? "active" : ""}`}>Равнодействующая сил</a></li>
        <li><span className="submenu-title">ДАВЛЕНИЕ ТВЁРДЫХ ТЕЛ, ЖИДКОСТЕЙ И ГАЗОВ</span></li>
        <li><a href="/courses/physics/7-class/lecture/pressure" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/pressure" ? "active" : ""}`}>Давление</a></li>
        <li><a href="/courses/physics/7-class/lecture/solid-pressure" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/solid-pressure" ? "active" : ""}`}>Передача давления твёрдыми телами</a></li>
        <li><a href="/courses/physics/7-class/lecture/liquid-pressure" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/liquid-pressure" ? "active" : ""}`}>Передача давления жидкостями и газами. Закон Паскаля</a></li>
        <li><a href="/courses/physics/7-class/lecture/hydrostatic-pressure" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/hydrostatic-pressure" ? "active" : ""}`}>Гидростатическое давление. Гидростатический парадокс</a></li>
        <li><a href="/courses/physics/7-class/lecture/communicating-vessels" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/communicating-vessels" ? "active" : ""}`}>Сообщающиеся сосуды</a></li>
        <li><a href="/courses/physics/7-class/lecture/atmospheric-pressure" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/atmospheric-pressure" ? "active" : ""}`}>Вес воздуха. Атмосферное давление</a></li>
        <li><a href="/courses/physics/7-class/lecture/barometers" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/barometers" ? "active" : ""}`}>Влияние высоты над уровнем моря. Барометры</a></li>
        <li><a href="/courses/physics/7-class/lecture/manometers" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/manometers" ? "active" : ""}`}>Манометры</a></li>
        <li><a href="/courses/physics/7-class/lecture/hydraulic-devices" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/hydraulic-devices" ? "active" : ""}`}>Принцип действия поршневого насоса, гидравлического пресса</a></li>
        <li><a href="/courses/physics/7-class/lecture/archimedes" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/archimedes" ? "active" : ""}`}>Сила Архимеда</a></li>
        <li><span className="submenu-title">РАБОТА И МОЩНОСТЬ. ЭНЕРГИЯ</span></li>
        <li><a href="/courses/physics/7-class/lecture/work" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/work" ? "active" : ""}`}>Механическая работа</a></li>
        <li><a href="/courses/physics/7-class/lecture/power" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/power" ? "active" : ""}`}>Мощность</a></li>
        <li><a href="/courses/physics/7-class/lecture/simple-machines" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/simple-machines" ? "active" : ""}`}>Простые механизмы (рычаг, блок, клин, винт)</a></li>
        <li><a href="/courses/physics/7-class/lecture/leverage" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/leverage" ? "active" : ""}`}>Принцип рычага. Равновесие сил</a></li>
        <li><a href="/courses/physics/7-class/lecture/force-moment" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/force-moment" ? "active" : ""}`}>Момент силы</a></li>
        <li><a href="/courses/physics/7-class/lecture/block-system" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/block-system" ? "active" : ""}`}>Подвижный и неподвижный блоки</a></li>
        <li><a href="/courses/physics/7-class/lecture/golden-rule" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/golden-rule" ? "active" : ""}`}>Золотое правило механики</a></li>
        <li><a href="/courses/physics/7-class/lecture/center-of-gravity" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/center-of-gravity" ? "active" : ""}`}>Центр тяжести тела. Равновесие</a></li>
        <li><a href="/courses/physics/7-class/lecture/efficiency" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/efficiency" ? "active" : ""}`}>КПД механизма</a></li>
        <li><a href="/courses/physics/7-class/lecture/energy" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/energy" ? "active" : ""}`}>Энергия. Потенциальная и кинетическая энергия</a></li>
        <li><a href="/courses/physics/7-class/lecture/energy-conversion" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/energy-conversion" ? "active" : ""}`}>Переход одного вида энергии в другой</a></li>
        <li><a href="/courses/physics/7-class/lecture/fluid-energy" className={`submenu-item ${location.pathname === "/courses/physics/7-class/lecture/fluid-energy" ? "active" : ""}`}>Энергия движущейся жидкости и газа</a></li>
      </ul>
      <div className="sidebar-label">ФИЗИКА (7 класс)</div>
    </div>
  );
}

export default Sidebar;
