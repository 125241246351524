
import React, { useEffect } from "react";
import { setupInertiaTrainer } from "../../../../assets/interactives/class-7/inertia/inertia-train-script";
import "../../../../assets/interactives/class-7/inertia/inertia-train-styles.css";

const InertiaTrainer = ({title}) => {
    useEffect(() => {
        setupInertiaTrainer('inertia-train-container');
    }, []);

    return (
        <div id="inertia-train-container">
			{/* Заголовок */}
			<div className="text-head">
				<h1>{title}</h1>
				<hr className="hr-vertical-lines" />
			</div>
			<div className="block-area-inertia-train">
				<div className="container-inertia-train">
					<div className="question-area-inertia-train">
						<p id="question-inertia-train">Что произойдёт с тележками при освобождении пружины?</p>
						<form id="answerFormInertiaTrain">
							<label>
								<input type="radio" name="answer" value="1" defaultChecked />
								Тележки начнут двигаться с одинаковой скоростью
							</label>
							<br />
							<label>
								<input type="radio" name="answer" value="2" />
								Скорость левой тележки будет больше
							</label>
							<br />
							<label>
								<input type="radio" name="answer" value="3" />
								Скорость правой тележки будет больше
							</label>
							<br />
						</form>
					</div>
					<div className="comment-area-inertia-train" id="commentAreaInertiaTrain" style={{ display: 'none' }}>
						<p id="commentTextInertiaTrain">Ваш ответ верный. Левое значение массы тележки было больше.</p>
					</div>
					<div className="line-inertia-train">
						<img id="tableInertiaTrain" className="table-inertia-train" src="/img/inertia/interactives/table.svg" alt="Стол" />
						<img id="leftTrainInertiaTrain" className="train-inertia-train" src="/img/inertia/interactives/left-train.svg" alt="Левая тележка" />
						<img id="rightTrainInertiaTrain" className="train-inertia-train" src="/img/inertia/interactives/right-train.svg" alt="Правая тележка" />
						<img id="leftWeightSmallInertiaTrain" className="weight-inertia-train" src="/img/inertia/interactives/weight-small.svg" alt="Маленькая гиря слева" draggable="true" />
						<img id="rightWeightSmallInertiaTrain" className="weight-inertia-train" src="/img/inertia/interactives/weight-small.svg" alt="Маленькая гиря справа" draggable="true" />
						<img id="leftWeightBigInertiaTrain" className="weight-inertia-train" src="/img/inertia/interactives/weight-big.svg" alt="Большая гиря слева" draggable="true" />
						<img id="rightWeightBigInertiaTrain" className="weight-inertia-train" src="/img/inertia/interactives/weight-big.svg" alt="Большая гиря справа" draggable="true" />
						<div id="leftLineInertiaTrain" className="dashed-line-inertia-train"></div>
						<div id="rightLineInertiaTrain" className="dashed-line-inertia-train"></div>
					</div>
				</div>
				<div className="buttons-inertia-train center-buttons-inertia-train">
					<button id="startExperimentInertiaTrain"> <img src="/img/inertia/interactives/power_button.svg" alt="Назад" /> ЗАПУСТИТЬ</button>
					<button id="resetInertiaTrain">СБРОСИТЬ <img src="/img/inertia/interactives/reset_button.svg" alt="Назад" /></button>
					<button id="nextStageInertiaTrain" style={{ display: 'none' }}>Далее</button>
				</div>
			</div>
        </div>
    );
};

export default InertiaTrainer;